import SlideClip, { SlideClipView } from "./SlideClip";
import { useCallback, useEffect } from "react";
import { useDragLayer, useDrop } from "react-dnd";

import { SlideData } from "../video/@types/SlideData";
import logger from "../logger";
import tw from "twin.macro";
import { useSetIsDraggingAnything } from "../stores/app";
import { useSlides } from "../stores/project";
import { useZoomLevel } from "../stores/timeline";

const StyledContainer = tw.div`
w-full h-28

flex flex-col flex-grow-0

box-border 
p-2 

shadow-inner

bg-gray-700
`;

const TrackTitle = tw.h2`
    m-1
    text-sm
    uppercase
    font-bold
    select-none

    text-gray-200
    opacity-60

    flex-grow-0
    w-full
    h-5
`;

const Clips = tw.div`
    w-auto
    flex
    flex-row

    flex-grow
    flex-nowrap

    relative
    
    z-20
`;

const StyledDragLayer = tw.div`
h-full w-full absolute 

flex

pointer-events-none

z-50 
`;

const log = logger("ClipsDragLayer");

const ClipsDragLayer = () => {
  const { itemType, isDragging, item, offset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    offset: monitor.getDifferenceFromInitialOffset(),
    isDragging: monitor.isDragging(),
  }));

  const setIsDraggingAnything = useSetIsDraggingAnything();

  log.debug("isDragging", isDragging);

  useEffect(() => {
    setIsDraggingAnything((dragging) => {
      const new_value = isDragging || dragging;
      log.debug("setIsDraggingAnything", new_value);
      return new_value;
    });

    return () => {
      setIsDraggingAnything(false);
    };
  }, [setIsDraggingAnything, isDragging]);

  if (!isDragging || !offset) {
    return null;
  }

  log.debug("current item", item, offset);

  if (itemType !== SlideClip.dropType) {
    return null;
  }

  return (
    <StyledDragLayer>
      <div
        style={{
          transform: `translate(${offset.x}px, 0px)`,
          pointerEvents: "none",
        }}
      >
        <SlideClipView data={item} />
      </div>
    </StyledDragLayer>
  );
};

export const SlidesTrack = () => {
  const [clips, setClips] = useSlides();

  const moveClip = useCallback(
    (id, from) => {
      // @ts-ignore
      setClips(
        // @ts-ignore
        clips
          // @ts-ignore
          .map((clip) =>
            clip.id === id
              ? { ...clip, from, to: clip.to - (clip.from - from) }
              : clip
          )
          // @ts-ignore
          .sort((a, b) => a.from - b.from)
      );
    },
    [clips, setClips]
  );

  const { pixelsToFrames } = useZoomLevel();

  const [, drop] = useDrop<SlideData>(
    () => ({
      accept: SlideClip.dropType,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        if (delta) {
          const from = item.from + pixelsToFrames(delta.x);

          moveClip(item.id, from);
        }
      },
    }),
    [moveClip]
  );

  return (
    <StyledContainer>
      <TrackTitle>Slides</TrackTitle>
      <Clips ref={drop}>
        <>
          {
            // @ts-ignore
            clips.map((clip) => (
              <SlideClip key={`${clip.id}`} data={clip} />
            ))
          }
          <ClipsDragLayer />
        </>
      </Clips>
    </StyledContainer>
  );
};

export default SlidesTrack;
