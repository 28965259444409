export const SCREEN_FORMATS = ["wide", "square", "vertical"] as const;

export type ScreenFormat = typeof SCREEN_FORMATS[number];

export type CompositionFormat = { width: number; height: number; fps: number };

export const SCREENS: Record<ScreenFormat, CompositionFormat> = {
  wide: {
    width: 1920,
    height: 1080,
    fps: 30,
  },
  square: {
    width: 1080,
    height: 1080,
    fps: 30,
  },
  vertical: {
    width: 1080,
    height: 1920,
    fps: 30,
  },
} as const;

// This seems to duplicate CompositionFormat type from above,
// but it doesn't, as this type is only valid for the members of SCREENS,
// not any CompositionFormat-like objects

export type Screen = typeof SCREENS[ScreenFormat];

export default SCREENS;
