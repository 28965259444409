import { Player, PlayerRef } from "@remotion/player";
import React, { FC, forwardRef, memo } from "react";

import ErrorCatcher from "../../atoms/ErrorCatcher";
import { ProjectConfig } from "../../video";
import ProjectCore from "../../video/components/ProjectCore";

type PlayerProps = Parameters<typeof Player>[0];

const HANDLED_PROPS = [
  "component",
  "inputProps",
  "compositionWidth",
  "compositionHeight",
  "durationInFrames",
  "fps",
  "clickToPlay",
] as const;

type ProjectPlayerProps = Omit<PlayerProps, typeof HANDLED_PROPS[number]> & {
  project: ProjectConfig;
};

export const NaiveProjectPlayer: FC<ProjectPlayerProps> = memo(
  forwardRef<PlayerRef, ProjectPlayerProps>(
    ({ project, style, ...props }, ref) => {
      return (
        <Player
          {...props}
          component={ProjectCore}
          inputProps={project}
          ref={ref}
          compositionWidth={project.screen.width}
          compositionHeight={project.screen.height}
          durationInFrames={project.duration * project.screen.fps}
          fps={project.screen.fps}
          style={{
            ...style,
            pointerEvents: "none",
          }}
        />
      );
    }
  )
);

console.log(React.version);

export const ProjectPlayer = forwardRef<PlayerRef, ProjectPlayerProps>(
  (props, ref) => (
    <ErrorCatcher>
      <NaiveProjectPlayer
        {...props}
        ref={ref}
        errorFallback={({ error }) => {
          throw error;
        }}
      />
    </ErrorCatcher>
  )
);

export default ProjectPlayer;
