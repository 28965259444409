import { register } from "../SlideRegistry";
import AudioViz from "./AudioViz";
import Blank from "./Blank";
import ClassicWaveSquare from "./ClassicWaveSquare";
import LogoTextSlide from "./LogoTextSlide";
import Placeholder from "./Placeholder";
import PodcastsTips from "./PodcastsTips";

register(Blank);

register(LogoTextSlide);
register(AudioViz);

register(Placeholder);

register(PodcastsTips);

register(ClassicWaveSquare);
