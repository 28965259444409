import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

interface Props {
  children?: React.ReactChild;
  verticalCenter?: boolean;
}

export const Container = styled.div<Props>((props) => [
  tw`
    flex
    flex-col
    items-center
    h-screen
    w-full

  `,
  props.verticalCenter
    ? tw`
    justify-center
  `
    : tw`
      pt-5
      pb-5
    `,
]);

export default Container;
