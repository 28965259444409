import { WebVTTParser } from "webvtt-parser";

export type CueBaseElement = {
  children: CueElement[];
  type: string;
};

export type CueObjectElement = CueBaseElement & {
  type: "object";
  name: string;
  classes: string[];
};

export type CueTextElement = CueBaseElement & {
  type: "text";
  value: string;
};

export type CueElement = CueBaseElement | CueBaseElement;

export type Cue = {
  id: string;
  startTime: number;
  endTime: number;
  text: string;
  size: number;
  textPosition: number;
  alignment: "start" | "center" | "end";
  tree: {
    children: CueElement[];
  };
};

export const WebVTT = (
  file: string
): {
  cues: Cue[];
  errors: any[];
  time: number;
  cuesAtTime: (time: number) => Cue[];
} => {
  const parsed = new WebVTTParser().parse(file);

  parsed.cuesAtTime = (time: number) =>
    parsed.cues.filter(
      (cue: { startTime: number; endTime: number }) =>
        cue.startTime <= time && cue.endTime > time
    );

  return parsed;
};

export default WebVTT;
