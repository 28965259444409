import { MouseEvent as ReactMouseEvent, useCallback, useEffect } from "react";
import { useDrag } from "react-dnd";

import { useCurrentClip } from "../../stores/currentClip";
import SlideClipView, { SlideClipProps } from "./SlideClipView";

// Stolen from https://github.com/react-dnd/react-dnd/blob/e8bd6436548d96f6d6594f763752f424c2e0834b/packages/backend-html5/src/getEmptyImage.ts
let emptyImage: HTMLImageElement | undefined;

function getEmptyImage(): HTMLImageElement {
  if (!emptyImage) {
    emptyImage = new Image();
    emptyImage.src =
      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
  }

  return emptyImage;
}

export const SlideClip = ({ data }: SlideClipProps) => {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: SlideClip.dropType,
      item: data,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [data]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const [selected, setSelected] = useCurrentClip();

  const selectClip = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      if (selected !== data.id) {
        setSelected(data.id);
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [selected, setSelected, data.id]
  );

  return (
    <SlideClipView
      data={data}
      ref={drag}
      hidden={isDragging}
      onMouseDown={selectClip}
      selected={selected === data.id}
    />
  );
};

SlideClip.dropType = Symbol("slide-clip");

export default SlideClip;
