import { PlayerRef } from "@remotion/player";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import debounce from "lodash.debounce";
import { useMemo } from "react";

import logger from "../logger";

type PlayerRefStore = PlayerRef | null | undefined;
const _playerRefStore = atom<PlayerRefStore>(undefined);

const currentFrameStore = atom<number>(0);

export const usePlayerFrame = () => useAtomValue(currentFrameStore);

const playerScrobblingStore = atom<boolean>(false);

export const usePlayerScrobblingFlag = () => useAtom(playerScrobblingStore);

const playerRefStore = atom<PlayerRefStore, PlayerRefStore>(
  (get) => get(_playerRefStore),
  (get, set, newValue) => {
    logger("playerRefStore").debug("Got new playerRef", newValue);
    const oldValue = get(_playerRefStore);
    const scrobbling = get(playerScrobblingStore);

    if (oldValue !== newValue) {
      set(_playerRefStore, newValue);
      newValue?.addEventListener(
        "timeupdate",
        ({ detail: { frame } }) =>
          newValue.isPlaying() && !scrobbling && set(currentFrameStore, frame)
      );
    }
  }
);

export const usePlayerRef = () => useAtom(playerRefStore);
export const useSetPlayerRef = () => useSetAtom(playerRefStore);

const PLAYER_REF_PASSTHROUGH_METHOD = [
  "play",
  "pause",
  "toggle",
  "getCurrentFrame",
  "isPlaying",
  "seekTo",
] as const;

type PlayerRemote = Pick<
  PlayerRef,
  typeof PLAYER_REF_PASSTHROUGH_METHOD[number]
>;

export const usePlayerRemote = () => {
  const player = useAtomValue(playerRefStore);
  const setCurrentFrame = useSetAtom(currentFrameStore);

  return useMemo(() => {
    const log = logger("usePlayerRemote");

    const remote: Partial<PlayerRemote> = PLAYER_REF_PASSTHROUGH_METHOD.reduce(
      (acc, method) => ({
        ...acc,
        [method]: player ? player[method] : () => {},
      }),
      {}
    );

    log.debug(remote);

    const originalSeekTo = remote.seekTo!;

    const debouncedSeekTo = debounce((frame) => {
      log.debug("seeking to with args", frame);
      originalSeekTo(frame);
    }, 300);

    remote.seekTo = (frame, ui_only = false) => {
      setCurrentFrame(frame);
      if (!ui_only) {
        debouncedSeekTo(frame);
      }
    };

    return remote;
  }, [
    // We need to disable eslint to use spreading here
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...PLAYER_REF_PASSTHROUGH_METHOD.map((m) => player?.[m]),
    setCurrentFrame,
    player,
  ]) as PlayerRemote;
};
