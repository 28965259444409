import { CSSProperties } from "react";
import { AbsoluteFill } from "remotion";
import { TransitionImplementationProps } from "remotion-transition-series/lib/components/Transition";

import logger from "../../utils/logger";
import DirectionProps from "./directions";

const log = logger("transitions:FlipTransition");

export const Flip: (
  props: TransitionImplementationProps & DirectionProps
) => JSX.Element = ({
  direction = "left",
  progress,
  exitingElement = null,
  enteringElement = null,
}) => {
  const cardStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
  };

  const transformMethod = `rotate${
    direction === "left" || direction === "right" ? "Y" : "X"
  }`;

  const transformGoal = direction === "left" || direction === "up" ? -180 : 180;

  const transform = `${transformMethod}(${(progress * transformGoal).toFixed(
    2
  )}deg)`;

  log.debug({
    transformMethod,
    transformGoal,
    progress,
    transform,
  });

  return (
    <AbsoluteFill
      style={{
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
        perspective: 1000,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          textAlign: "center",
          transformStyle: "preserve-3d",
          transform,
        }}
      >
        <div style={cardStyle}>{exitingElement}</div>
        <div style={{ ...cardStyle, transform: `${transformMethod}(180deg)` }}>
          {enteringElement}
        </div>
      </div>
    </AbsoluteFill>
  );
};

export default Flip;
