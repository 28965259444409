import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { configure, HotKeys } from "react-hotkeys";

import ProjectPreview from "../../molecules/ProjectPreview/ProjectPreview";
import SlideInspector from "../../molecules/SlideInspector";
import SlideLibrary from "../../molecules/SlideLibrary";
import Timeline from "../../molecules/Timeline";
import {
  StyledEditor,
  StyledMain,
  StyledNav,
  StyledSidebar,
  StyledTitle,
} from "./SlideEditor.styled";

configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

export const SlideEditor = () => {
  return (
    <HotKeys root>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <style>{"* { user-select: none }"}</style>
        <StyledEditor>
          <StyledNav>
            <StyledTitle>Slide Editor</StyledTitle>
          </StyledNav>
          <StyledMain>
            <StyledSidebar>
              <SlideLibrary />
              <SlideInspector />
            </StyledSidebar>
            <ProjectPreview />
            <Timeline />
          </StyledMain>
        </StyledEditor>
      </DndProvider>
    </HotKeys>
  );
};

export default SlideEditor;
