import { lazy } from "react";

import Config from "./Config";

const AudioViz = {
  key: "audioviz",
  Renderer: lazy(() => import("./Renderer")),
  Inspector: lazy(() => import("./Inspector")),
  Config,
};

export default AudioViz;
