import { AudioData, getAudioData } from "@remotion/media-utils";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";

import logger from "../utils/logger";

const _cache = atom<Record<string, any>>({});

const log = logger("useAudioDataWithCache");

export const useAudioDataWithCache = (
  url: string,
  clear_cache: boolean = false
): AudioData | null => {
  const [cache, setCache] = useAtom(_cache);

  log({ url, clear_cache });

  let current_data = cache[url];

  useEffect(() => {
    if (clear_cache) {
      setCache((cache) => ({ ...cache, [url]: null }));
      // We already return an empty cache before refilling it
      // eslint-disable-next-line
      current_data = null;
    }

    if (current_data) return;

    // Let's fetch data

    const cancelled = { cancel: false };

    const audioData = getAudioData(url);

    audioData
      .then((data) => {
        log({ fetchedDataAndSettingCache: data });

        if (cancelled.cancel) {
          log({ cancelled });
        } else {
          setCache((cache) => ({ ...cache, [url]: data }));
        }
      })
      .catch((err) =>
        log.error("An error occured fetching audio data for url", url, err)
      );

    return () => (cancelled.cancel = true) && void 0; // The void 0 is there only to void the return value for typescript to be happy
  }, [clear_cache, current_data, url, setCache]);

  log({ current_data });

  return cache[url] || null;
};

export default useAudioDataWithCache;
