import { lazy } from "react";

import Config from "./Config";

const ClassicWaveSquare = {
  key: "classicwavesquare",
  Renderer: lazy(() => import("./Renderer")),
  Inspector: lazy(() => import("./Inspector")),
  Config,
};

export default ClassicWaveSquare;
