import "./assets/styles.css";

import React, { useMemo } from "react";
import {
  AbsoluteFill,
  Audio,
  Sequence,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

import { SlideData } from "../@types/SlideData";
import { ProjectConfig } from "../ProjectConfig";
import TransparentBlackBackground from "../underlays/TransparentBlackBackground";
import logger from "../../utils/logger";
import WebVTT from "../utils/WebVTT";
import Background from "./Background";
import BorderProgress from "./BorderProgress";
import PodCloudLogo from "./PodCloudLogo";
import Slides from "./Slides";
import Subtitles from "./Subtitles";

const log = logger("ProjectCore");

export const ProjectCore: React.FC<ProjectConfig> = (projectConfig) => {
  log.debug("rendering ProjectCore...");

  const frame = useCurrentFrame();
  const { fps, durationInFrames } = useVideoConfig();

  const ProjectSubtitles = useMemo(() => {
    if (projectConfig.webvtt === null) {
      return null;
    }
    return WebVTT(projectConfig.webvtt);
  }, [projectConfig]);

  const subtitles_cues = useMemo(
    () =>
      ProjectSubtitles === null
        ? null
        : ProjectSubtitles.cuesAtTime(frame / fps),
    [ProjectSubtitles, frame, fps]
  );

  const sorted_slides = useMemo(
    () => projectConfig.slides.sort((a, b) => a.from - b.from),
    [projectConfig.slides]
  );

  const slide_cues = useMemo(() => {
    const padded_slides = [];

    const first_from = sorted_slides[0].from || 0;

    if (first_from > 0) {
      padded_slides.push({
        type: "blank",
        from: 0,
        to: first_from,
      } as SlideData);
    }

    for (let i = 0; i < sorted_slides.length; i++) {
      const slide = { ...sorted_slides[i] };
      const next_slide = sorted_slides[i + 1] || null;

      if (next_slide && next_slide.from < slide.to) {
        slide.to = next_slide.from;
      }

      padded_slides.push(slide);

      if (next_slide && next_slide.from > slide.to) {
        padded_slides.push({
          type: "blank",
          from: slide.to,
          to: next_slide.from,
        } as SlideData);
      }
    }

    return padded_slides;
  }, [sorted_slides]);

  log.trace("padded slides", slide_cues);

  const ProjectSlidesPresenter = useMemo(() => {
    log.debug("Rendering ProjectSlidesPresenter");
    return <Slides projectConfig={projectConfig} cues={slide_cues} />;
  }, [projectConfig, slide_cues]);

  return (
    <AbsoluteFill>
      <Background seed={projectConfig.title} />
      <Sequence
        from={Math.floor(fps / 4)}
        durationInFrames={durationInFrames - fps}
      >
        <Audio src={projectConfig.audio} />
        <TransparentBlackBackground
          animate
          opacity={0.3}
          animationDurationInFrames={fps / 3}
        >
          {ProjectSlidesPresenter}
        </TransparentBlackBackground>
      </Sequence>
      <BorderProgress />
      <PodCloudLogo position="top right" />
      {subtitles_cues !== null ? <Subtitles cues={subtitles_cues} /> : null}
    </AbsoluteFill>
  );
};

export default ProjectCore;
