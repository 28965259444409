import { useProjectScreen, useSlides } from "../stores/project";

import SlideRegistry from "../video/SlideRegistry";
import tw from "twin.macro";
import { useCallback } from "react";
import { usePlayerFrame } from "../stores/player";

const StyledContainer = tw.div`w-1/2 h-full flex flex-col justify-center items-center box-border p-2 bg-gray-600`;

const ScrollView = tw.div`w-full h-full flex-grow overflow-y-auto p-2 flex flex-wrap gap-3`;

const SlideType = tw.div`w-28 h-28 bg-gray-400 border border-white p-2 overflow-hidden rounded cursor-pointer`;

export const SlideLibrary = () => {
  const slides = SlideRegistry.getAll();

  const [screenConfig] = useProjectScreen();
  const currentFrame = usePlayerFrame();
  const [, setSlides] = useSlides();

  const addSlide = useCallback(
    (slide_id) => {
      // @ts-ignore
      setSlides((prev) => [
        ...prev,
        {
          id: +new Date(),
          type: slide_id,
          from: currentFrame,
          // @ts-ignore
          to: currentFrame + 10 * screenConfig.fps,
          config: slides[slide_id].Config,
        },
      ]);
    },
    // @ts-ignore
    [setSlides, currentFrame, screenConfig.fps, slides]
  );

  return (
    <StyledContainer>
      <h3>LIBRARY</h3>
      <ScrollView>
        {Object.keys(slides).map((slide_id) => (
          <SlideType onClick={() => addSlide(slide_id)}>
            {slide_id}
            <br />
            {JSON.stringify(slides[slide_id])}
          </SlideType>
        ))}
      </ScrollView>
    </StyledContainer>
  );
};

export default SlideLibrary;
