import {
  Easing,
  interpolate,
  random,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

import logger from "../../../utils/logger";

type BackgroundProps = {
  id?: string;
  style?: React.CSSProperties;
  color: string;
  rpm: number;
  scale: number;
  transform?: string;
};

export const Triangle: React.FC<BackgroundProps> = ({
  id,
  style,
  transform,
  rpm,
  color,
  scale,
  ...props
}) => {
  const { durationInFrames, fps, width } = useVideoConfig();
  const frame = useCurrentFrame();

  if (durationInFrames < fps * 5 /* seconds */) {
    const err = new Error(
      "Background duration is too short. 5 seconds is the minimum." +
        ` Got ${
          durationInFrames / fps
        } seconds. ( ${durationInFrames} frames, ${fps} fps) `
    );

    logger("Triangle").error(err);
    throw err;
  }

  const intro = {
    start: 0,
    end: 1.5 /* second */ * fps,
    onScreen: false,
  };

  const outro = {
    start: durationInFrames - 1.5 * fps,
    end: durationInFrames,
    onScreen: false,
  };

  intro.onScreen = frame >= intro.start && frame <= intro.end;
  outro.onScreen = frame >= outro.start && frame <= outro.end;

  const seed = random(JSON.stringify([id]));

  const baseRot = seed * 360 * 1.5;

  const rotation = interpolate(
    frame,
    [0, durationInFrames],
    [baseRot, (durationInFrames / fps / 60) * rpm * 360]
  );

  const wiggleAnimation = [[0, 0]];

  let nextChangeFrame = Math.min(5 * fps, durationInFrames);
  let nextChangeDir =
    random(seed + "first-change" + nextChangeFrame) - 0.5 > 0 ? 1 : -1;

  while (nextChangeFrame < durationInFrames) {
    wiggleAnimation.push([nextChangeFrame, nextChangeDir]);

    nextChangeDir = nextChangeDir * -1;
    nextChangeFrame =
      nextChangeFrame +
      (durationInFrames / 10) * random(seed + "change" + nextChangeFrame) * fps;
  }

  wiggleAnimation.push([nextChangeFrame, nextChangeDir]);

  const wiggle = interpolate(
    frame,
    wiggleAnimation.map((a) => a[0]),
    wiggleAnimation.map((a) => a[1]),
    { easing: Easing.inOut(Easing.cubic) }
  );

  const jump = interpolate(
    frame,
    [
      0,
      durationInFrames / 2 - fps / 5,
      durationInFrames / 2 + fps,
      durationInFrames,
    ],
    [0, -4, -5, -7],
    { easing: Easing.inOut(Easing.sin) }
  );

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: (scale * 3 * width) / 100,
        height: (scale * 3 * width) / 100,
        background: color || "#484ad5",
        clipPath: "polygon(50% 0, 0 100%, 100% 100%)",
        transform: `
				${transform} 
				translateX(${wiggle * 2}%)
				translateY(${jump * 2}%) 
				rotate(${rotation}deg)
				`,
        ...style,
      }}
      {...props}
    />
  );
};

export default Triangle;
