import { CSSProperties } from "react";
import {
  AbsoluteFill,
  interpolate,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

export const BorderProgress: React.FC<{}> = () => {
  const frame = useCurrentFrame();
  const { fps, durationInFrames, width, height } = useVideoConfig();

  if (durationInFrames === 1) {
    return null;
  }

  const borderSize = 10;
  const borderEager = 1.5 * fps;
  const progressAnimation = (max: number) =>
    interpolate(
      frame,
      [0, durationInFrames - borderEager],
      [0, max - borderSize],
      { extrapolateRight: "clamp" }
    );

  const Border = ({ style, ...props }: { style: CSSProperties }) => (
    <div
      style={{
        background: "#3e3f95",
        opacity: interpolate(
          frame,
          [0, borderEager, durationInFrames - borderEager, durationInFrames],
          [0, 0.5, 0.5, 0]
        ),
        position: "absolute",
        height: borderSize,
        width: borderSize,
        ...style,
      }}
      {...props}
    />
  );

  return (
    <AbsoluteFill>
      {[
        {
          top: 0,
          left: 0,
          width: progressAnimation(width),
        },
        {
          top: 0,
          right: 0,
          height: progressAnimation(height),
        },
        {
          bottom: 0,
          right: 0,
          width: progressAnimation(width),
        },
        {
          bottom: 0,
          left: 0,
          height: progressAnimation(height),
        },
      ].map((style) => (
        <Border {...{ style }} key={`border-${Object.keys(style).join("-")}`} />
      ))}
    </AbsoluteFill>
  );
};

export default BorderProgress;
