import {
  DOMAttributes,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GlobalHotKeys } from "react-hotkeys";
import tw from "twin.macro";

import Button from "../atoms/Button";
import logger from "../logger";
import { useIsDraggingAnything } from "../stores/app";
import {
  usePlayerFrame,
  usePlayerRemote,
  usePlayerScrobblingFlag,
} from "../stores/player";
import { useProjectConfig } from "../stores/project";
import { useZoomLevel } from "../stores/timeline";
import AudioTrack from "./AudioTrack";
import SlidesTrack from "./SlideTrack";

const StyledTimelineControls = tw.div`
  w-full h-auto
  pl-28
  pr-28
  flex flex-row
  justify-start
  items-center
`;

const StyledTimelineControl = tw.div`
  mr-1
  ml-1
`;

const StyledTimecode = tw(StyledTimelineControl)`
  text-2xl
`;

const log = logger("TimelineControls");
const TimelineControls = () => {
  const { toggle, seekTo } = usePlayerRemote();

  const { zoomPercentage, setZoomPercentage } = useZoomLevel();
  const [projectConfig] = useProjectConfig();

  const currentFrame = usePlayerFrame();

  const current_s = currentFrame / projectConfig.screen.fps;

  const ms = (current_s % 1).toFixed(3).substring("0.".length);
  const seconds = `${Math.floor(current_s) % 60}`.padStart(2, "0");
  const minutes = `${Math.floor(current_s / 60)}`.padStart(2, "0");
  const hours = `${Math.floor(current_s / (60 * 60))}`.padStart(2, "0");

  const timecode = `${hours}:${minutes}:${seconds}.${ms}`;

  const timecode_debug = `(${currentFrame}/${
    projectConfig.duration * projectConfig.screen.fps
  }) ---- ${current_s.toFixed(3)}`;

  log.debug(timecode_debug);

  const keyMap = {
    play: "space",
    rewind: "left",
    forward: "right",
  };

  const handlers = useMemo(
    () => ({
      play: (e?: KeyboardEvent) => {
        if (e?.repeat) return;

        log.debug("hotkey play : ", e);
        toggle(e as any);
      },
      rewind: () => {
        seekTo(currentFrame - Math.round(projectConfig.screen.fps / 3));
      },
      forward: () => {
        log.debug(
          "hotkey forward : ",
          currentFrame + Math.round(projectConfig.screen.fps / 3)
        );
        seekTo(currentFrame + Math.round(projectConfig.screen.fps / 3));
      },
    }),
    [toggle, currentFrame, projectConfig.screen.fps, seekTo]
  );

  return (
    <StyledTimelineControls>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />
      <StyledTimelineControl>
        <Button onClick={() => seekTo(0)} secondary size="sm">
          Restart
        </Button>
      </StyledTimelineControl>
      <StyledTimelineControl>
        <Button onClick={(e) => toggle(e)} secondary>
          Play/Pause
        </Button>
      </StyledTimelineControl>
      <StyledTimelineControl>
        Zoom :
        <Button
          onClick={() => setZoomPercentage(zoomPercentage - 5)}
          secondary
          size="sm"
        >
          -
        </Button>
        {zoomPercentage} %
        <Button
          onClick={() => setZoomPercentage(zoomPercentage + 5)}
          secondary
          size="sm"
        >
          +
        </Button>
      </StyledTimelineControl>
      <StyledTimecode>{timecode}</StyledTimecode>
      <StyledTimelineControl>{timecode_debug}</StyledTimelineControl>
    </StyledTimelineControls>
  );
};

const PlayerHead = tw.div`
absolute

top-0
left-0

w-0.5
h-full

margin-left[-0.125rem]
box-border

border-yellow-200
border-solid
bg-yellow-300

active:bg-yellow-200

opacity-60

rounded-2xl

z-30
`;

const TimelinePlayerHead = forwardRef<HTMLDivElement>((_props, ref) => {
  const currentFrame = usePlayerFrame();
  const [
    {
      screen: { fps },
    },
  ] = useProjectConfig();

  const { isPlaying } = usePlayerRemote();

  const [driftedFrame, setDriftedFrame] = useState(currentFrame);

  const { framesToPixels } = useZoomLevel();

  useEffect(() => setDriftedFrame(currentFrame), [currentFrame]);

  useEffect(() => {
    if (isPlaying()) {
      const timer = setTimeout(
        () => setDriftedFrame(driftedFrame + 1),
        1000 / fps
      );
      return () => clearTimeout(timer);
    }
  }, [isPlaying, setDriftedFrame, driftedFrame, fps]);

  return (
    <PlayerHead style={{ left: framesToPixels(driftedFrame) }} ref={ref} />
  );
});

const StyledTracksContainer = tw.div`
  relative
  h-full
  w-full

  box-border

  p-5
  pl-0

  overflow-x-scroll
`;

const TracksHolder = tw.div`
relative
box-border
`;

const TimelineTracks = forwardRef<
  HTMLDivElement,
  { width: number; children: ReactNode } & DOMAttributes<HTMLDivElement>
>(({ children, width, ...props }, ref) => {
  return (
    <TracksHolder {...props} style={{ width }} ref={ref}>
      <TracksPlayHeadHolder />
      {children}
    </TracksHolder>
  );
});

const TracksPlayHeadHolder = tw.div`
absolute

top-0
left-0
right-0
bottom-0

z-10

`;

const TracksContainer = () => {
  const [projectConfig] = useProjectConfig();
  const { pixelsToFrames, framesToPixels } = useZoomLevel();
  const { seekTo } = usePlayerRemote();

  const tracks = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const [scrobbling, setScrobbling] = usePlayerScrobblingFlag();

  const player_head = useRef(null);

  const scrobble = useCallback(
    (e) => {
      const frame = pixelsToFrames(
        e.clientX - (tracks.current?.getBoundingClientRect?.()?.left || 0)
      );
      logger("scrobbling").debug("frame", frame);
      seekTo(frame);
    },
    [pixelsToFrames, seekTo]
  );

  const isDraggingAnything = useIsDraggingAnything();

  const mouse_click: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.target === player_head.current) e.stopPropagation();

      const _scrobbling = e.buttons === 1;
      setScrobbling(_scrobbling);
      if (_scrobbling) {
        scrobble(e);
      }
    },
    [setScrobbling, scrobble, player_head]
  );

  const mouse_move: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.buttons !== 1) {
        setScrobbling(false);
        return;
      }

      if (scrobbling && !isDraggingAnything) {
        scrobble(e);
      }
    },
    [setScrobbling, scrobbling, scrobble, isDraggingAnything]
  );

  useLayoutEffect(() => {
    const cont = container.current;
    if (cont) {
      const scroll_helper = (evt: WheelEvent) => {
        const log = logger("scroll_helper");

        const direction = evt.shiftKey ? "scrollTop" : "scrollLeft";

        log.debug("scroll", evt);
        log.debug("cont", direction, cont[direction]);
        cont[direction] += evt.deltaY;
        log.debug("cont", direction, cont[direction]);
        evt.preventDefault();
      };

      cont.addEventListener("wheel", scroll_helper);

      return () => cont?.removeEventListener?.("wheel", scroll_helper);
    }
  }, []);

  return (
    <StyledTracksContainer ref={container}>
      <TimelineTracks
        ref={tracks}
        width={framesToPixels(
          projectConfig.duration * projectConfig.screen.fps
        )}
        onMouseMove={mouse_move}
        onMouseDown={mouse_click}
      >
        <TimelinePlayerHead ref={player_head} />
        <SlidesTrack />
        <AudioTrack />
      </TimelineTracks>
    </StyledTracksContainer>
  );
};

const StyledContainer = tw.div`
w-full h-1/3 

p-2 pl-0 pr-0

flex flex-col

shadow-inner

box-border

`;

const Timeline = () => (
  <StyledContainer>
    <TimelineControls />
    <TracksContainer />
  </StyledContainer>
);

export default Timeline;
