import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";

const forceInRange = (n: number, min: number, max: number) =>
  Math.max(min, Math.min(max, n));

const ZoomPercentageStore = atomWithStorage<number>("zoom-percentage", 20);

export const ZoomPercentage = atom<number, number>(
  (get) => forceInRange(get(ZoomPercentageStore), 5, 100),
  (_get, set, newValue) =>
    set(ZoomPercentageStore, forceInRange(newValue, 5, 100))
);

const ZOOM_RATIO = 2 * 10;

export const useZoomLevel = () => {
  const [zoomPercent, setZoomPercent] = useAtom(ZoomPercentage);

  return useMemo(
    () => ({
      zoomPercentage: zoomPercent,
      setZoomPercentage: setZoomPercent,
      framesToPixels: (frames: number) => (frames * zoomPercent) / ZOOM_RATIO,
      pixelsToFrames: (pixels: number) =>
        Math.round((pixels * ZOOM_RATIO) / zoomPercent),
    }),
    [zoomPercent, setZoomPercent]
  );
};
