export type ClassicWaveSquareConfig = {
  color: string;
  cover: string;
  epTitle: string;
  podTitle: string;
};

export const defaultConfig: ClassicWaveSquareConfig = {
  color: "#4b3bff",
  cover: "",
  epTitle: "",
  podTitle: "",
};

export const loopConfig = {
  loopSeconds: 20,
  availableOn: ["square"],
};

export default defaultConfig;
