import { lazy } from "react";

import Config from "./Config";

const LogoTextSlide = {
  key: "logo_text_slide",
  Renderer: lazy(() => import("./Renderer")),
  Inspector: lazy(() => import("./Inspector")),
  Config,
};

export default LogoTextSlide;
