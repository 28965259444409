import { memo, useMemo } from "react";
import { AbsoluteFill, random } from "remotion";

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const generateColorsArray = () => {
  let colors = ["255, 80, 80", "80, 255, 80", "80, 80, 255", "80, 80, 80"];

  for (let index = 0; index < 10; index++) {
    colors = shuffle([...colors, ...colors]);
  }
  return colors;
};

export const Placeholder: React.FC<{}> = memo(({ children }) => {
  const colors = useMemo(generateColorsArray, [children]);

  const color =
    colors[Math.floor(random(JSON.stringify(colors)) * colors.length)];

  return (
    <AbsoluteFill
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: `rgba(${color}, 0.5)`,
      }}
    >
      <pre
        style={{
          fontSize: "2vw",
          margin: 20,
          textShadow: "0px 0px 8px #000",
          zIndex: 1,
          maxHeight: "90%",
          overflow: "scroll",
        }}
      >
        {children}
      </pre>
    </AbsoluteFill>
  );
});

export default Placeholder;
