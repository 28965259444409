import auth from "./auth";
import podcast from "./podcast";
import video from "./video";

export const host = "http://localhost:4000";

export const checkStatus = (res: Response): Response | Promise<never> => {
  if (res.ok) {
    return res;
  } else {
    if (res.status === 401) {
      // TODO: Changer ça par la bonne URL quand on aura tej le /app
      window.location.href = "/app/login#redirect=" + window.location.pathname;
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    } else {
      return res.text().then((msg: string) => {
        throw new Error(msg);
      });
    }
  }
};

const api = {
  auth,
  podcast,
  video,
};

export default api;
