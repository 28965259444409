import React from "react";
import tw from "twin.macro";

const CardComponent = tw.div`
  bg-white
  rounded
  shadow
  max-w-xl
  w-full
  p-2.5
  mb-5
  `;

const TitleComponent = tw.p`
  text-primary
  text-center
  font-bold
  text-xl
`;

interface Props {
  children?: React.ReactNode;
  title?: string;
}

export const Card = ({
  children,
  title,
  ...props
}: Props): React.ReactElement => (
  <CardComponent {...props}>
    {title ? <TitleComponent>{title}</TitleComponent> : null}
    {children}
  </CardComponent>
);

export default Card;
