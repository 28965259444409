import styled from "styled-components";
import tw from "twin.macro";

type ButtonProps = {
  primary?: boolean;
  secondary?: boolean;
  variant?: keyof typeof ButtonStyles;
  size?: "sm" | "md" | "lg";
};

const ButtonStyles = {
  default: tw`
  bg-primary 
  text-white

  hover:bg-primary-dark
  disabled:bg-primary-light
  disabled:hover:cursor-not-allowed
  `,
  primary: tw`
  bg-blue-900
  text-white

  hover:bg-gray-800
  `,
  secondary: tw`
  bg-indigo-900
  text-white

  hover:bg-indigo-800
  `,
  danger: tw`
  bg-red-700
  text-white

  hover:bg-red-600
`,
};

export const SubmitButton = styled.input<ButtonProps>(
  ({ primary, secondary, variant, size }) => [
    tw`
  mr-1 
  ml-1 
  
  border 
  border-white 
  border-opacity-50 
  
  rounded 
  `,
    ButtonStyles[
      variant && ButtonStyles[variant]
        ? variant
        : primary
        ? "primary"
        : secondary
        ? "secondary"
        : "default"
    ],
    size === "sm"
      ? tw`px-2 py-1 text-sm`
      : size === "lg"
      ? tw`px-6 py-3 text-lg`
      : tw`px-4 py-2`,
  ]
);

export default SubmitButton;
