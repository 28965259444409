import { Slide } from "../../@types/Slide";

export const Blank = {
  key: "blank",
  Renderer: () => null,
  Inspector: () => null,
  Config: {},
} as Slide<{}>;

export default Blank;
