import "../slides/register";

import { ReactNode } from "react";
import { useVideoConfig } from "remotion";
import TransitionSeries from "remotion-transition-series";

import { SlideData } from "../@types/SlideData";
import { ProjectConfig } from "../ProjectConfig";
import SlideRegistry from "../SlideRegistry";
import {
  FadeTransition,
  FlipTransition,
  PanTransition,
  SlideTransition,
  TransitionHelper,
} from "../transitions";
import logger from "../../utils/logger";
import Placeholder from "./Placeholder";

export type SlideProps = {
  from: number;
  to: number;
  prevSlide: Omit<Omit<SlideProps, "nextSlide">, "prevSlide">;
  nextSlide: Omit<Omit<SlideProps, "nextSlide">, "prevSlide">;
};

export type RegisterSlideFn = <T = any>(
  key: string,
  renderFn: (props: SlideProps & T) => ReactNode
) => void;

export type VideoSlidesHelper = { registerSlide: RegisterSlideFn };

export type VideoSlides = (helpers: VideoSlidesHelper) => void;

type SlidesProps = {
  projectConfig: ProjectConfig;
  cues: CueData[];
};

type CueData = SlideData & {
  prevSlide?: SlideData;
  nextSlide?: SlideData;
};

const log = logger("Slides");

const Slides = ({ projectConfig, cues }: SlidesProps) => {
  const { width, height } = useVideoConfig();

  return (
    <TransitionSeries>
      {cues.map((cue, i) => {
        type TransRegexpResult = [any, string, string];

        const [, transitionType, transitionArgs]: TransRegexpResult =
          (cue.type.match(
            /^transition\.(pan|slide|fade|flip)\.?(.*)$/
          ) as TransRegexpResult | null) ||
          ([] as unknown as TransRegexpResult);

        if (transitionType) {
          if (["pan", "slide", "flip"].includes(transitionType)) {
            const transitionDirection = (
              transitionArgs?.match?.(/^(up|right|down|left)$/)
                ? transitionArgs
                : "left"
            ) as keyof TransitionHelper;

            return (
              <TransitionSeries.Transition
                key={`slide-${width}x${height}-${i}`}
                durationInFrames={cue.to - cue.from}
                name={`transition: ${transitionType} ${transitionDirection}`}
                transitionComponent={
                  (transitionType === "pan"
                    ? PanTransition
                    : transitionType === "slide"
                    ? SlideTransition
                    : FlipTransition)[transitionDirection]
                }
              />
            );
          }
          if (transitionType === "fade") {
            return (
              <TransitionSeries.Transition
                key={`slide-${width}x${height}-${i}`}
                durationInFrames={cue.to - cue.from}
                name={`transition: ${transitionType}`}
                transitionComponent={FadeTransition}
              />
            );
          }
        }

        const Slide = SlideRegistry.get(cue.type);

        log.debug(
          "Rendering slide",
          cue.type,
          cue.to,
          cue.from,
          "duration:",
          cue.to - cue.from,
          Slide
        );

        return (
          <TransitionSeries.Sequence
            key={`slide-${width}x${height}-${i}`}
            durationInFrames={cue.to - cue.from}
            name={`slide: ${cue.type}`}
          >
            {Slide ? (
              <Slide.Renderer
                projectConfig={projectConfig}
                cue={cue}
                config={cue.config}
              />
            ) : (
              <Placeholder>
                Unknown slide :<br />
                {JSON.stringify(
                  {
                    ...cue,
                    prevSlide: {
                      ...cue.prevSlide,
                      prevSlide: "[circular]",
                      nextSlide: "[circular]",
                    },
                    nextSlide: {
                      ...cue.nextSlide,
                      prevSlide: "[circular]",
                      nextSlide: "[circular]",
                    },
                  },
                  null,
                  3
                )}
              </Placeholder>
            )}
          </TransitionSeries.Sequence>
        );
      })}
    </TransitionSeries>
  );
};

export default Slides;
