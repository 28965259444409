export const RegexTime = new RegExp(/[0-5][0-9]:[0-5][0-9]:[0-5][0-9]/);

export function isTimeValid(hms: string) {
  return hms.match(RegexTime) !== null;
}

export function stringToSeconds(hms: string) {
  if (!isTimeValid(hms)) return -1;

  let splitted = hms.split(":");

  return (
    parseInt(splitted[2]) +
    parseInt(splitted[1]) * 60 +
    parseInt(splitted[0]) * 3600
  );
}

function z(num: number) {
  if (num < 10) {
    return "0" + num;
  } else {
    return "" + num;
  }
}

export function secondsToString(seconds: number) {
  let s = seconds;

  let heure = Math.trunc(s / 3600);
  s = s % 3600;
  let minute = Math.trunc(s / 60);
  s = s % 60;

  return `${z(heure)}:${z(minute)}:${z(s)}`;
}
