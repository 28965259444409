import { memo, useMemo } from "react";
import { useProjectAudio, useProjectScreen } from "../stores/project";

import { AudioData } from "@remotion/media-utils";
import logger from "../logger";
import tw from "twin.macro";
import useAudioDataWithCache from "../hooks/useAudioDataWithCache";
import { useZoomLevel } from "../stores/timeline";

const StyledContainer = tw.div`
w-full h-16

flex flex-col flex-grow-0

box-border 
p-2 

shadow-inner

bg-indigo-900
`;

const TrackTitle = tw.h2`
    m-1
    text-sm
    uppercase
    font-bold
    select-none

    text-gray-200
    opacity-60

    flex-grow-0
    w-full
    h-5
`;

const filterData = (
  rawData: AudioData["channelWaveforms"][number],
  samples = 3600 // Number of samples we want to have in our final data set
) => {
  const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
  const filteredData = [];
  for (let i = 0; i < samples; i++) {
    let blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
  }
  return filteredData;
};

const normalizeData = (filteredData: Array<number>) => {
  const multiplier = Math.pow(Math.max(...filteredData), -1);
  return filteredData.map((n) => n * multiplier);
};

const BAR_WIDTH = 1;

const Bars = memo<{ data: number[]; invert?: boolean }>(
  ({ data = [], invert = false }) => (
    <div
      style={{
        position: "relative",
        height: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: invert ? "flex-start" : "flex-end",
      }}
    >
      {data.map((h: number, index) => (
        <div
          key={`bar-${index}`}
          style={{
            height: `${h * 100}%`,
            width: BAR_WIDTH,
            boxSizing: "border-box",
            backgroundColor: "#ff3",
          }}
        />
      ))}
    </div>
  )
);

export const AudioTrack = () => {
  const [videoAudio] = useProjectAudio();
  // @ts-ignore
  const [{ fps }] = useProjectScreen();
  // @ts-ignore
  const audioData = useAudioDataWithCache(videoAudio);
  const { framesToPixels } = useZoomLevel();

  logger("AudioTrack").log(audioData);

  const [track0, track1] = useMemo(() => {
    logger("AudioTrack").log("En train de cramer ta ram pour le memo frere");
    if (
      !audioData ||
      !audioData.channelWaveforms ||
      audioData.numberOfChannels === 0
    ) {
      return [null, null];
    }

    const durationInFrames = audioData.durationInSeconds * fps;
    const durationInPx = framesToPixels(durationInFrames);

    const samples = Math.floor(durationInPx / BAR_WIDTH);

    const track0 = normalizeData(
      filterData(audioData.channelWaveforms[0], samples)
    );
    const track1 =
      audioData.numberOfChannels === 2
        ? normalizeData(filterData(audioData.channelWaveforms[1], samples))
        : [...track0];
    return [track0, track1];
  }, [audioData, framesToPixels, fps]);

  if (!track0) return null;

  return (
    <StyledContainer>
      <TrackTitle>Audio</TrackTitle>
      <div style={{ marginTop: -10, paddingLeft: framesToPixels(fps / 2) }}>
        <Bars data={track0} />
        <Bars data={track1} invert />
      </div>
    </StyledContainer>
  );
};

export default AudioTrack;
