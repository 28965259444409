import tw from "twin.macro";
import Input, { InputProps } from "../atoms/Input";
import Label from "../atoms/Label";

type Props = InputProps & {
  label?: string;
  error?: string;
  showError?: boolean;
};

const LabelError = tw.label`
  text-error
  block
  text-xs
  italic
  mb-1
`;

export const InputWithLabel = ({
  label,
  error,
  showError = true,
  ...props
}: Props): React.ReactElement => {
  return (
    <>
      {label ? <Label>{label}</Label> : null}
      <Input {...props} hasError={!!error} />
      {error && showError ? <LabelError>{error}</LabelError> : null}
    </>
  );
};

export default InputWithLabel;
