import styled from "styled-components";
import tw from "twin.macro";

export type InputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "ref" | "as"
> & {
  hasError?: boolean;
};

const Input = styled.input<InputProps>(({ hasError }) => [
  tw`
  bg-gray-50
  border
  rounded

  focus:outline-none
  p-1
  text-gray-800

  placeholder:text-gray-300
  placeholder:font-light
  w-full
`,
  hasError
    ? tw`
    border-error
  `
    : null,
]);

export default Input;
