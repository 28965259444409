import tw from "twin.macro";

import Button from "../../atoms/Button";
import { useSetPlayerRef } from "../../stores/player";
import { useProjectConfig } from "../../stores/project";
import { SCREENS } from "../../video";
import { ProjectPlayer } from "./ProjectPlayer";

const StyledContainer = tw.div`
  w-1/2 h-2/3 
  overflow-hidden 

  bg-gray-900 

  flex 
  flex-col 
  justify-center 
  items-center
`;

const StyledControls = tw.div`
  w-full 
  h-auto 
  
  flex 
  flex-row 
  justify-center 
  items-center

  flex-grow

  p-0

  relative
  
  bg-gray-800

  drop-shadow-lg
`;
const StyledTitle = tw.h2`
    text-sm
    uppercase
    font-bold
    select-none

    text-gray-200
    opacity-60

    box-border
    p-1

    inline-block

    absolute
    left-0
    top-0

    mt-1
    ml-1
`;

const StyledPlayer = tw.div`h-5/6 w-full`;

const StyledButtonGroup = tw.div`flex flex-row content-center justify-between`;

export const ProjectPreview = () => {
  const setPlayerRef = useSetPlayerRef();
  const [projectConfig, setProjectConfig] = useProjectConfig();

  return (
    <StyledContainer>
      <StyledControls>
        <StyledTitle>Preview</StyledTitle>
        <StyledButtonGroup>
          {(Object.keys(SCREENS) as Array<keyof typeof SCREENS>).map((key) => (
            <Button
              key={key}
              onClick={() =>
                setProjectConfig((config) => ({
                  ...config,
                  screen: SCREENS[key],
                }))
              }
              size="sm"
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Button>
          ))}
        </StyledButtonGroup>
      </StyledControls>
      <StyledPlayer>
        <ProjectPlayer
          project={projectConfig}
          ref={(ref) => setPlayerRef(ref)}
          style={{ width: "100%", height: "96%", marginTop: "1%" }}
        />
      </StyledPlayer>
    </StyledContainer>
  );
};

export default ProjectPreview;
