import { checkStatus, host } from ".";
import { User } from "../types";

const auth = {
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ jwt: string; user: User }> => {
    return fetch(`${host}/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  register: (body: {
    email: string;
    password: string;
    passwordRepeat: string;
  }): Promise<any> => {
    console.log(body);
    return fetch(`${host}/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  whoami: (): Promise<{ jwt: string; user: User }> => {
    return fetch(`${host}/whoami`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          return res.text().then((msg: string) => {
            throw new Error(msg);
          });
        }
      })
      .then((res) => res.json());
  },
};

export default auth;
