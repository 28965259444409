import { lazy } from "react";

import Config from "./Config";

const Placeholder = {
  key: "placeholder",
  Renderer: lazy(() => import("./Renderer")),
  Inspector: lazy(() => import("./Inspector")),
  Config,
};

export default Placeholder;
