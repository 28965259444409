import React, { CSSProperties } from "react";
import {
  AbsoluteFill,
  interpolate,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

import logger from "../../utils/logger";
import {
  Cue,
  CueBaseElement,
  CueObjectElement,
  CueTextElement,
} from "../utils/WebVTT";

const subtitlesStyles: { [classname: string]: React.CSSProperties } = {
  CenterTitle: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2vh 15% 0",
    fontSize: "min(8vw, 100px)",
  },
};

const MIN_FONT_SIZE = 42;
const MAX_FONT_SIZE = 70;
const MAX_CHARS_PER_LINE = 45;

const getTextWidth: ((text: string, font: string) => number) & {
  canvas?: HTMLCanvasElement;
} = function (text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));

  const context = canvas.getContext("2d");
  if (!context) throw new Error("Could not get canvas context");

  context.font = font;

  return context.measureText(text).width;
};

const log = logger("Subtitles");

export const Subtitles: React.FC<{
  cues: Cue[];
}> = ({ cues }) => {
  const frame = useCurrentFrame();
  const { fps, durationInFrames, width, height } = useVideoConfig();

  const layout_width = width * (70 / 100);

  const fontFamily = '"Lato", sans-serif';
  const fontSize = Math.max(
    MIN_FONT_SIZE,
    Math.min(MAX_FONT_SIZE, layout_width / MAX_CHARS_PER_LINE)
  );

  return durationInFrames === 1 ? null : (
    <AbsoluteFill
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: `translateY(${interpolate(frame, [fps, fps * 1.2], [1, 0], {
          extrapolateRight: "clamp",
        })}%)`,
        opacity: interpolate(frame, [fps, fps * 1.5], [0, 1], {
          extrapolateRight: "clamp",
        }),
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          marginLeft: height > 1080 ? -((width * 15) / 100) : 0,
          width: width * (85 / 100),
          height:
            height * ((height > 1080 ? 50 : width > 1080 ? 90 : 85) / 100),
        }}
      >
        {cues
          .filter((cue) => !`${cue.id || ""}`.match(/^metadata-/))
          .map((cue, i) => {
            const styles: CSSProperties = {
              fontSize: fontSize,
              fontFamily: fontFamily,
              color: "white",
              textAlign: "center",
            };

            if (
              typeof cue.alignment === "string" &&
              cue.alignment.match(/^(start|center|end)$/)
            ) {
              styles.alignSelf = cue.alignment;
            }

            if (typeof cue.size === "number") {
              styles.fontSize = ((styles.fontSize as number) * cue.size) / 100;
            }

            if (typeof cue.textPosition === "number") {
              styles.position = "absolute";
              styles.left = 0;
              styles.right = 0;
              styles.top = `${cue.textPosition as number}%`;
            }

            const renderText = (cue: Cue | CueTextElement) =>
              ("type" in cue && cue.type === "text"
                ? cue.value
                : "text" in cue && cue.text
                ? cue.text
                : ""
              )
                .replace(/ :/g, "\xa0:")
                .replace(/ !/g, "\xa0!")
                .replace(/ \?/g, "\xa0?")
                .split("\n")
                .map((t, i, tts) => {
                  const isLast = i === tts.length - 1;
                  const isFirst = i === 0;

                  const fontConfig = `${styles.fontSize}px ${styles.fontFamily}`;

                  let width = getTextWidth(t, fontConfig);

                  let prev_width = getTextWidth(tts[i - 1] || "", fontConfig);
                  let next_width = getTextWidth(tts[i + 1] || "", fontConfig);

                  const treshold = getTextWidth("marge", fontConfig);

                  log.debug({ t, width, prev_width, next_width, treshold });

                  if (
                    !isFirst &&
                    width < prev_width &&
                    width + treshold >= prev_width
                  ) {
                    width = prev_width;
                  }

                  if (
                    !isLast &&
                    width < next_width &&
                    width + treshold >= next_width
                  ) {
                    width = next_width;
                  }

                  // Back adjust prev_width to ensure borders are cut if needed
                  if (prev_width < width && prev_width + treshold >= width) {
                    prev_width = width;
                  }

                  // Early adjust next_width to ensure borders are cut if needed
                  if (next_width < width && next_width + treshold >= width) {
                    next_width = width;
                  }

                  log.debug({
                    t,
                    adjusted: width,
                  });

                  const radius = 8;
                  const borderTop = width <= prev_width ? 0 : radius;
                  const borderBottom = width <= next_width ? 0 : radius;

                  const lineStyles: CSSProperties = {
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                    paddingTop: isFirst ? "0.2em" : 0,
                    paddingBottom: "0.2em",
                    display: "inline-block",
                    width,
                    background: "rgba(0,0,0,0.5)",
                    boxSizing: "content-box",
                    borderTopLeftRadius: borderTop,
                    borderTopRightRadius: borderTop,
                    borderBottomLeftRadius: borderBottom,
                    borderBottomRightRadius: borderBottom,
                  };

                  log.debug(lineStyles);

                  return (
                    <div key={`subtitle-${i}`}>
                      <div style={lineStyles}>
                        {t.trim().replace(/\.$/, isLast ? "" : ".")}
                      </div>
                    </div>
                  );
                });

            const renderCueTree = (els: CueBaseElement[]) =>
              (
                els.reduce((acc: React.ReactNode[], el) => {
                  const rendered =
                    el.type === "text" ? (
                      renderText(el as CueTextElement)
                    ) : el.type === "object" ? (
                      <div
                        style={((el as CueObjectElement).classes || []).reduce(
                          (styles, classname) => ({
                            ...styles,
                            ...(subtitlesStyles[classname] || {}),
                          }),
                          {}
                        )}
                      >
                        {renderCueTree(el.children)}
                      </div>
                    ) : null;
                  return [...acc, rendered];
                }, []) as React.ReactNode[]
              ).filter((a) => a !== null);

            return (
              <div style={styles} key={`cue-${i}`}>
                {renderCueTree(cue.tree.children)}
              </div>
            );
          })}
      </div>
    </AbsoluteFill>
  );
};

export default Subtitles;
