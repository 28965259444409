import { checkStatus, host } from ".";
import { Podcast, PodcastData } from "../types";

const podcast = {
  new: (body: { rss: string }): Promise<any> => {
    return fetch(`${host}/podcast`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  index_from_user: (): Promise<Podcast[]> => {
    return fetch(`${host}/podcast`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  get_one_podcast_data: (id: number): Promise<PodcastData> => {
    return fetch(`${host}/podcast/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default podcast;
