import { useMemo } from "react";
import {
  AbsoluteFill,
  interpolate,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

import logger from "../../utils/logger";

const log = logger("TransparentBlackBackground");

export const TransparentBlackBackground: React.FC<{
  opacity?: number;
  animate?: boolean | "start" | "end";
  animationDurationInFrames?: number;
}> = ({ opacity = 0.5, animate, animationDurationInFrames, children }) => {
  const { durationInFrames, fps } = useVideoConfig();
  const frame = useCurrentFrame();

  const background_animation_duration = animationDurationInFrames ?? fps / 2;

  const startAnimation = useMemo(
    () =>
      interpolate(frame, [0, background_animation_duration], [0, opacity], {
        extrapolateRight: "clamp",
      }),
    [frame, background_animation_duration, opacity]
  );

  const stopAnimationStart = durationInFrames - background_animation_duration;

  const stopAnimation = useMemo(
    () =>
      interpolate(frame, [stopAnimationStart, durationInFrames], [opacity, 0], {
        extrapolateLeft: "clamp",
      }),
    [frame, stopAnimationStart, durationInFrames, opacity]
  );

  const background_opacity = (() => {
    if (durationInFrames < fps) return opacity;

    if (animate === "start") {
      log.debug("start animation", startAnimation);
      return startAnimation;
    }

    if (animate === "end") {
      log.debug("end animation", stopAnimation);
      return stopAnimation;
    }

    if (!!animate) {
      const value = frame < stopAnimationStart ? startAnimation : stopAnimation;
      log.debug("both animation", value);

      return value;
    }

    log.debug("static opacity", opacity);
    return opacity;
  })();

  log.debug("TransparentBlackBackground", {
    animate,
    frame,
    durationInFrames,
    background_opacity,
  });

  return (
    <AbsoluteFill
      style={{
        background: `rgba(0,0,0,${background_opacity})`,
      }}
    >
      {children}
    </AbsoluteFill>
  );
};

export default TransparentBlackBackground;
