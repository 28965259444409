// @ts-nocheck

import {
  NewProjectConfig,
  ProjectConfig,
  ProjectConfigFixture,
} from "../video";

import { atomWithStorage } from "jotai/utils";
import { focusAtom } from "jotai/optics";
import { useAtom } from "jotai";

export const ProjectConfigStore = atomWithStorage<ProjectConfig>(
  "project_config",
  NewProjectConfig({
    audio: ProjectConfigFixture.audio,
    slides: ProjectConfigFixture.slides,
  })
);

export const useProjectConfig = () => useAtom(ProjectConfigStore);

export const ProjectConfigSlides = focusAtom(ProjectConfigStore, (optic) =>
  optic.prop("slides")
);

export const useSlides = () => useAtom(ProjectConfigSlides);

export const ProjectConfigVideoAudio = focusAtom(ProjectConfigStore, (optic) =>
  optic.prop("audio")
);

export const useProjectAudio = () => useAtom(ProjectConfigVideoAudio);

export const ProjectConfigScreen = focusAtom(ProjectConfigStore, (optic) =>
  optic.prop("screen")
);

export const useProjectScreen = () => useAtom(ProjectConfigScreen);

export default ProjectConfigStore;
