export type LogoTextConfig = {
  text: string;
  src: string;
};

export const defaultConfig: LogoTextConfig = {
  text: "Logo Text",
  src: "https://assets.podcloud.fr/images/press/icon-tile-2800.jpg",
};

export default defaultConfig;
