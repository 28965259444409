export type PodcastsTipsConfig = {
  titleColor: string;
  titleSize?: number;
  subtitle?: string;
  subtitleSize?: number;
  split?: boolean;
};

export const defaultConfig: PodcastsTipsConfig = {
  titleColor: "#a2a2a2",
};

export default defaultConfig;
