import React, { ReactNode } from "react";

import logger from "../logger";

class ErrorCatcher extends React.Component<
  { children: ReactNode | ReactNode[] },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    logger("ErrorCatcher").error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    logger("ErrorCatcher").error(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "royalblue",
          color: "white",
          width: "100%",
          height: "100%",
        }}
      >
        An error occured loading player
      </div>
    ) : (
      <>{this.props.children}</>
    );
  }
}

export default ErrorCatcher;
