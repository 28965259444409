import { ReactNode } from "react";
import { TransitionImplementationProps } from "remotion-transition-series/lib/components/Transition";

export const Fade: (props: TransitionImplementationProps) => ReactNode = ({
  progress,
  exitingElement = null,
  enteringElement = null,
}) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          opacity: 1 - progress,
        }}
      >
        {exitingElement}
      </div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          opacity: progress,
        }}
      >
        {enteringElement}
      </div>
    </>
  );
};

export default Fade;
