import { Easing } from "remotion";

import logger from "../../utils/logger";
import { DirectionProps, DIRECTIONS } from "./directions";
import Fade from "./Fade";
import Flip from "./Flip";
import { Pan } from "./Pan";
import { Slide } from "./Slide";

export { Pan, Slide };
type TransitionComponent = typeof Slide | typeof Pan;

const log = logger("transitions");

log.debug("Creating transition cache");
const transitionsCache = new Map<string, TransitionComponent>();

export const transition = (
  TransitionComp: TransitionComponent,
  direction: DirectionProps["direction"],
  progressFn?: (progress: number) => number
) => {
  const cache_key = `${TransitionComp.name}_${direction}_${(
    progressFn || "noProgressFn"
  ).toString()}`;
  const cached = transitionsCache.get(cache_key);

  if (cached) return cached;

  log.debug(`creating trans : ${cache_key}`);

  const transition = ({
    progress,
    ...props
  }: Parameters<TransitionComponent>[0]) => (
    <TransitionComp
      {...props}
      progress={
        progressFn ? progressFn(progress) : Easing.inOut(Easing.exp)(progress)
      }
      direction={direction}
    />
  );

  transitionsCache.set(cache_key, transition);

  return transition;
};

export type TransitionHelper = {
  up: TransitionComponent;
  right: TransitionComponent;
  down: TransitionComponent;
  left: TransitionComponent;
};

const MakeTransitionHelper: (
  TransitionComponent: TransitionComponent
) => TransitionHelper = (TransitionComponent: TransitionComponent) =>
  Object.freeze(
    Object.defineProperties(
      {},
      DIRECTIONS.reduce(
        (acc, direction) => ({
          ...acc,
          [direction]: {
            get: () => transition(TransitionComponent, direction),
          },
        }),
        {}
      )
    )
  ) as TransitionHelper;

export const PanTransition = MakeTransitionHelper(Pan);
export const SlideTransition = MakeTransitionHelper(Slide);
export const FlipTransition = MakeTransitionHelper(Flip);
export const FadeTransition = Fade;
