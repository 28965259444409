import {
  AbsoluteFill,
  Easing,
  interpolate,
  random,
  Sequence,
  spring,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

import Triangle from "./Background/Triangle";

type BackgroundProps = { seed?: string };

export const Background: React.FC<BackgroundProps> = ({ seed }) => {
  const { durationInFrames, fps, width, height } = useVideoConfig();
  const frame = useCurrentFrame();

  if (durationInFrames < fps * 6 /* seconds */) {
    return (
      <Sequence from={-6 * fps} durationInFrames={12 * fps + durationInFrames}>
        <Background seed={seed} />
      </Sequence>
    );
  }

  const intro = {
    start: 0,
    end: 1.5 /* second */ * fps,
    onScreen: false,
  };

  const outro = {
    start: durationInFrames - 1.5 * fps,
    end: durationInFrames,
    onScreen: false,
  };

  intro.onScreen = frame >= intro.start && frame <= intro.end;
  outro.onScreen = frame >= outro.start && frame <= outro.end;

  const driver = spring({
    fps: interpolate(frame, [0, intro.end], [fps, fps * 3]),
    frame,
  });

  const topPosition = (() => {
    const topPosition = interpolate(driver, [0, 1], [150, -10]);

    if (outro.onScreen) {
      const goingDownPosition = interpolate(
        frame,
        [outro.start, outro.end],
        [-10, 150],
        {
          easing: Easing.in(Easing.cubic),
        }
      );

      return Math.max(topPosition, goingDownPosition);
    }

    return topPosition;
  })();

  const rpm = interpolate(
    frame,
    [
      0,
      intro.end,
      intro.end * 2,
      outro.start - (outro.end - outro.start),
      outro.start,
      outro.end,
    ],
    [2600, 1200, 300, 300, 600, 900],
    { easing: Easing.inOut(Easing.cubic) }
  );

  const gridSize = { rows: 3, cols: 3 };

  const triangleHeight = height / gridSize.rows;
  const triangleWidth = width / gridSize.cols;

  const grid: {
    [row: string]: {
      [col: string]: {
        id: string;
        color: string;
        rpm: number;
        scale: number;
        transform: string;
      };
    };
  } = {};

  const grid_seed = `grid-` + seed;

  const cycleColors = ((...colors: string[]) => {
    const handle = { current: 0 };

    return () => {
      handle.current = ++handle.current % colors.length;
      return colors[handle.current];
    };
  })("#484ad5", "#ee4423", "#f5d67a");

  for (let row = 0; row < gridSize.rows * 1.5; row += 0.5) {
    grid[`${row}`] = {};

    cycleColors();

    for (let col = 0; col < gridSize.cols * 1.5; col += 0.5) {
      if (col % 2 === 0) cycleColors();

      const seed = JSON.stringify([
        grid_seed,
        row,
        col,
        triangleWidth,
        triangleHeight,
      ]);

      const random_displace_x_base =
        (random(seed + "render-displace-y") - 0.5) * triangleWidth;

      const random_displace_x = interpolate(
        frame,
        [-fps, durationInFrames],
        [random_displace_x_base / 4, random_displace_x_base],
        { easing: Easing.inOut(Easing.cubic) }
      );

      const random_displace_y_base =
        (random(seed + "render-displace-y") * triangleHeight) / 2;

      const random_displace_y = interpolate(
        frame,
        [-fps, durationInFrames],
        [random_displace_y_base / 4, random_displace_y_base],
        { easing: Easing.inOut(Easing.cubic) }
      );

      grid[`${row}`][`${col}`] = {
        id: seed,
        color: cycleColors(),
        rpm: (random(seed + "-rpm") * rpm) / 50,
        scale: random(seed + "-scale") * 2 + 1,
        transform: `translate(${
          col * triangleWidth - triangleWidth / 1.2 - random_displace_x
        }px, ${
          row * triangleHeight - triangleHeight / 10 - random_displace_y
        }px)`,
      };
    }
  }

  return (
    <AbsoluteFill
      style={{
        background: "#03020e",
      }}
    >
      <AbsoluteFill
        style={{
          transform: `translateY(${topPosition}%)`,
        }}
      >
        {Object.values(grid)
          .map((o) => Object.values(o))
          .flat()
          .map((triangle, index) => (
            <Triangle
              {...triangle}
              key={`triangle-${index}`}
              id={`triangle-${index}`}
            />
          ))}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export default Background;
