import { useAtom } from "jotai";
import React from "react";

import { useNavigate, useLocation } from "react-router";
import { hasCheckLoginAtom, userAtom } from "../stores/user";

import { ROLES } from "../types";

interface Props {
  children: any;
  role?: ROLES;
}

export default function NeedLogin({
  children,
  role,
}: Props): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const [user] = useAtom(userAtom);
  const [hasCheckLogin] = useAtom(hasCheckLoginAtom);

  if (hasCheckLogin) {
    if (user !== undefined) {
      if (role === undefined) {
        return children;
      } else {
        if (user.roles.includes(role)) {
          return children;
        } else {
          navigate("/dashboard");
          return <></>;
        }
      }
    } else {
      navigate("/login#redirect=" + location.pathname);
      return <></>;
    }
  } else {
    return <></>;
  }
}
