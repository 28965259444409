import tw from "twin.macro";
import { useNavigate } from "react-router-dom";

export const YouPodLogoComponent = tw.img`
  h-[40px]
  hover:cursor-pointer
`;

export const YouPodLogo = (): React.ReactElement => {
  let navigate = useNavigate();

  return (
    <YouPodLogoComponent
      onClick={() => navigate("/dashboard")}
      src={`${process.env.PUBLIC_URL}/img/topbar_logo.png`}
    />
  );
};

export default YouPodLogo;
