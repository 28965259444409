import {
  faInstagram,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCancel,
  faPlus,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled, { css } from "styled-components";
import tw, { theme } from "twin.macro";

import api from "../../api";
import Button from "../../atoms/Button";
import Card from "../../atoms/Card";
import Container from "../../atoms/Container";
import InputWithLabel from "../../molecules/InputWithLabel";
import TopBar from "../../molecules/TopBar/TopBar";
import { userAtom } from "../../stores/user";
import { ItemData, Podcast, PodcastData } from "../../types";
import { isEmail } from "../../utils/email";
import { isTimeValid, secondsToString, stringToSeconds } from "../../utils/hms";
import { Slide, SlideConfig } from "../../video/@types/Slide";
import SlideRegistry from "../../video/SlideRegistry";

const HorizontalContainer = tw.div`
  flex
  items-center
`;

const HorizontalContainerCenter = tw.div`
  flex
  justify-between
`;

const AddPodcastIconContainer = tw.div`
  bg-gray-800
  rounded
  width[40px]
  height[40px]
  flex
  justify-center
  items-center

  hover:cursor-pointer
`;

const AddPodcastIcon = (props: any) => (
  <AddPodcastIconContainer {...props}>
    <FontAwesomeIcon icon={faPlus} style={{ margin: 0, fontSize: "30px" }} />
  </AddPodcastIconContainer>
);

const PodcastIconElement = tw.img`
  rounded
  width[80px]
  height[80px]
  mr-1

  hover:cursor-pointer
`;

const PodcastIcon = ({
  podcast,
  onClick,
}: {
  podcast: Podcast;
  onClick: any;
}) => (
  <PodcastIconElement
    src={podcast.cover}
    alt={podcast.title}
    onClick={onClick}
  />
);

const SubTitle = tw.h2`
  text-primary
`;

const Select = tw.select`
  bg-gray-50
  border
  rounded

  focus:outline-none
  p-1
  text-gray-800

  placeholder:text-gray-300
  placeholder:font-light
  w-full
`;

const VideoFormatContainer = styled.div<{ selected: boolean }>(
  ({ selected }) => [
    tw`
    rounded
    bg-white

    flex
    flex-col
    justify-center
    items-center

    w-1/3

    border
    border-gray-200

    transition
    hover:cursor-pointer`,
    selected
      ? tw`
        bg-primary
      `
      : null,
  ]
);

const VideoFormatTitle = styled.p<{ selected: boolean }>(({ selected }) => [
  tw`
  text-black
`,
  selected
    ? tw`
      text-white
    `
    : null,
]);

const VideoFormatImg = styled.img<{ selected: boolean }>(({ selected }) => [
  tw`
  w-full
  max-h-32
`,
  selected
    ? tw`
      invert
    `
    : null,
]);

const VideoFormatDescription = styled.p<{ selected: boolean }>(
  ({ selected }) => [
    tw`
  font-light
  text-gray-600
  text-xs
  text-center
`,
    selected
      ? tw`
        text-gray-200
      `
      : null,
  ]
);

const VideoDuration = tw.p`
  text-center
  text-xl
  text-primary
  font-bold
`;

const SocialAvailableContainer = tw.div`
  flex
  items-center
`;

const SocialIconContainer = styled.div<{
  available: boolean;
  mixed?: boolean;
}>(({ available, mixed }) => [
  tw`
      rounded
      width[30px]
      height[30px]
      flex
      justify-center
      items-center
      m-1

      transition
    `,
  available && !mixed
    ? tw`
        bg-valid
      `
    : tw`
        bg-error
      `,
  mixed
    ? css({
        background: `linear-gradient(135deg, ${theme(
          "colors.valid"
        )} 0%, ${theme("colors.valid")} 50%, ${theme(
          "colors.error"
        )} 50%, ${theme("colors.error")} 100%);`,
      })
    : null,
]);

const SocialTitle = tw.span`
  font-bold
  text-black
`;

const SocialDescription = tw.p`
  text-black
  font-light
`;

const SocialAvailable = ({
  duration,
  maxDuration,
  icon,
  socialName,
  socialDescription,
}: {
  duration: number;
  maxDuration: number | number[];
  icon: IconDefinition;
  socialName: string;
  socialDescription: string;
}) => {
  if (Array.isArray(maxDuration)) {
    let available = true;
    let mixed = undefined;
    if (
      maxDuration.filter((dur) => duration <= dur).length === maxDuration.length
    ) {
      available = true;
    } else if (maxDuration.filter((dur) => duration <= dur).length === 0) {
      available = false;
    } else {
      mixed = true;
    }

    return (
      <SocialAvailableContainer>
        <SocialIconContainer available={available} mixed={mixed}>
          <FontAwesomeIcon icon={icon} />
        </SocialIconContainer>
        <SocialDescription>
          <SocialTitle>{socialName}</SocialTitle>: {socialDescription}
        </SocialDescription>
      </SocialAvailableContainer>
    );
  } else {
    return (
      <SocialAvailableContainer>
        <SocialIconContainer available={duration <= maxDuration}>
          <FontAwesomeIcon icon={icon} />
        </SocialIconContainer>
        <SocialDescription>
          <SocialTitle>{socialName}</SocialTitle>: {socialDescription}
        </SocialDescription>
      </SocialAvailableContainer>
    );
  }
};

export default function MainPage() {
  const [user] = useAtom(userAtom);

  useEffect(() => {
    setEmailAddress(user?.email || "");
  }, [user]);

  // Choix du podcast
  const [isAddPodcastOpen, setIsAddPodcastOpen] = useState(false);
  const [addPodcastState, setAddPodcastState] = useState("");

  const queryClient = useQueryClient();

  const { data: podcasts } = useQuery("podcast", api.podcast.index_from_user);
  const { mutate: addPodcast } = useMutation(api.podcast.new, {
    onSuccess: (body) => {
      queryClient.setQueryData<Podcast[] | undefined>("podcast", (before) =>
        before === undefined ? undefined : [...before, body]
      );
      setIsAddPodcastOpen(false);
    },
  });

  console.log(podcasts);

  function handleAddFeed() {
    if (addPodcastState === "") return;

    addPodcast({ rss: addPodcastState });
  }

  // Choix de l'épisode
  const [selectedPodcast, setSelectedPodcast] = useState<Podcast | undefined>(
    undefined
  );
  const [selectedEpisode, setSelectedEpisode] = useState<undefined | ItemData>(
    undefined
  );

  const { data: podcastData } = useQuery(
    ["podcast", selectedPodcast?.id || -1],
    () => api.podcast.get_one_podcast_data(selectedPodcast!.id),
    {
      enabled: selectedPodcast !== undefined,
      retry: false,
      staleTime: 30 * 1000,
      onSuccess: (body) => {
        handleChangeSelectedEpisode(body.items[0].entry_id, body);
        setPodTitle(body.title);
        setPodSubTitle(body.itunes_subtitle);

        // TODO: Ajouter l'import de l'email depuis le RSS
        // if (emailAddress === "") {
        //   setEmailAddress(body.)
        // }
      },
    }
  );

  function handleChangeSelectedEpisode(entry_id: string, data?: PodcastData) {
    let podData: PodcastData | undefined;

    if (data !== undefined) {
      podData = data;
    } else {
      podData = podcastData;
    }

    let episode = podData!.items.find((item) => item.entry_id === entry_id);

    setSelectedEpisode(episode!);
    setEpTitle(episode!.title);
    setVideoEnd("0" + episode!.itunes.duration);
    setVideoDuration(stringToSeconds("0" + episode!.itunes.duration));
  }

  console.log(podcastData);

  // Choix du format
  type Format = "wide" | "square" | "vertical";
  const [selectedFormat, setSelectedFormat] = useState<undefined | Format>(
    undefined
  );
  const [selectedSlide, setSelectedSlide] = useState<
    undefined | Slide<SlideConfig>
  >(undefined);

  console.log(selectedSlide);

  function handleChangeFormat(format: Format) {
    setSelectedFormat(format);

    if (format === "square") {
      setSelectedSlide(SlideRegistry.get("classicwavesquare"));
      console.log(SlideRegistry.get("classicwavesquare"));
    }
  }

  // Personalisation
  const [epTitle, setEpTitle] = useState("");
  const [podTitle, setPodTitle] = useState("");
  const [podSubTitle, setPodSubTitle] = useState("");

  // Choix de la durée
  const [videoStart, setVideoStart] = useState("00:00:00");
  const [videoEnd, setVideoEnd] = useState("00:00:00");
  const [videoDuration, setVideoDuration] = useState(0);
  const [isVideoStartError, setIsVideoStartError] = useState<
    string | undefined
  >(undefined);
  const [isVideoEndError, setIsVideoEndError] = useState<string | undefined>(
    undefined
  );

  function handleChangeVideoStart(e: any) {
    setVideoStart(e.target.value);

    if (!isTimeValid(e.target.value)) {
      setIsVideoStartError("Mauvais format");
    } else if (stringToSeconds(e.target.value) >= stringToSeconds(videoEnd)) {
      setIsVideoStartError("Le début est après la fin");
    } else {
      setIsVideoStartError(undefined);
      updateDuration(e.target.value, videoEnd);
    }
  }

  function handleChangeVideoEnd(e: any) {
    setVideoEnd(e.target.value);

    if (!isTimeValid(e.target.value)) {
      setIsVideoEndError("Mauvais format");
      return;
    } else if (
      stringToSeconds(e.target.value) >
      stringToSeconds("0" + selectedEpisode!.itunes.duration)
    ) {
      setIsVideoEndError("Durée plus grande que celle de votre épisode");
    } else {
      setIsVideoEndError(undefined);
      updateDuration(videoStart, e.target.value);
    }
  }

  function updateDuration(start: string, end: string) {
    setVideoDuration(stringToSeconds(end) - stringToSeconds(start));
  }

  // Publication
  const [emailAddress, setEmailAddress] = useState("");

  const { mutate: addVideo } = useMutation(api.video.new);

  function startConvert() {
    if (
      selectedEpisode === undefined ||
      selectedFormat === undefined ||
      !isEmail(emailAddress) ||
      isVideoStartError !== undefined ||
      isVideoEndError !== undefined
    )
      return;

    addVideo({
      audio: selectedEpisode.enclosure.url,
      cover: selectedEpisode.itunes.image,
      email: emailAddress,
      format: selectedFormat,
      startTime: videoStart,
      endTime: videoEnd,
      epTitle,
      podTitle,
      podSubTitle,
    });
  }

  return (
    <>
      <TopBar />

      <Container>
        <>
          <Card title="Etape 1: Choix de l'épisode">
            {isAddPodcastOpen ? (
              <>
                <SubTitle>Ajoutez un podcast</SubTitle>
                <HorizontalContainer>
                  <InputWithLabel
                    placeholder="http://exemple.lepodcast.fr/rss"
                    value={addPodcastState}
                    onChange={(e: any) => setAddPodcastState(e.target.value)}
                    error={addPodcastState === "" ? "Required" : undefined}
                    showError={false}
                    type="url"
                  />
                  <Button
                    size="sm"
                    disabled={addPodcastState === ""}
                    onClick={handleAddFeed}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ margin: 0, fontSize: "25px" }}
                    />
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => setIsAddPodcastOpen(false)}
                  >
                    <FontAwesomeIcon
                      icon={faCancel}
                      style={{ margin: 0, fontSize: "25px" }}
                    />
                  </Button>
                </HorizontalContainer>
              </>
            ) : (
              <>
                <SubTitle>Choisissez votre podcast</SubTitle>
                <HorizontalContainer>
                  {podcasts?.map((pod) => (
                    <PodcastIcon
                      podcast={pod}
                      onClick={() => setSelectedPodcast(pod)}
                      key={pod.id}
                    />
                  ))}
                  <AddPodcastIcon
                    onClick={() => {
                      setIsAddPodcastOpen(true);
                      setAddPodcastState("");
                    }}
                  />
                </HorizontalContainer>
              </>
            )}

            {podcastData !== undefined ? (
              <>
                <SubTitle>Choisissez votre épisode</SubTitle>
                <Select
                  value={selectedEpisode?.entry_id}
                  onChange={(e) => handleChangeSelectedEpisode(e.target.value)}
                >
                  {podcastData.items.map((item) => (
                    <option value={item.entry_id} key={item.entry_id}>
                      {item.title}
                    </option>
                  ))}
                </Select>
              </>
            ) : null}
          </Card>

          {selectedEpisode !== undefined ? (
            <Card title="Etape 2: Personnalisation">
              <SubTitle>Choix du format</SubTitle>
              <HorizontalContainerCenter>
                <VideoFormatContainer
                  selected={selectedFormat === "wide"}
                  onClick={() => handleChangeFormat("wide")}
                >
                  <VideoFormatTitle selected={selectedFormat === "wide"}>
                    Horizontal
                  </VideoFormatTitle>
                  <VideoFormatImg
                    alt="Horizontal"
                    selected={selectedFormat === "wide"}
                    src={`${process.env.PUBLIC_URL}/img/video/wide.svg`}
                  />
                  <VideoFormatDescription selected={selectedFormat === "wide"}>
                    Idéal pour YouTube
                  </VideoFormatDescription>
                </VideoFormatContainer>
                <VideoFormatContainer
                  selected={selectedFormat === "square"}
                  onClick={() => handleChangeFormat("square")}
                >
                  <VideoFormatTitle selected={selectedFormat === "square"}>
                    Carré
                  </VideoFormatTitle>
                  <VideoFormatImg
                    selected={selectedFormat === "square"}
                    alt="Carré"
                    src={`${process.env.PUBLIC_URL}/img/video/square.svg`}
                  />
                  <VideoFormatDescription
                    selected={selectedFormat === "square"}
                  >
                    Idéal pour Twitter, Facebook, Instagram
                  </VideoFormatDescription>
                </VideoFormatContainer>
                <VideoFormatContainer
                  selected={selectedFormat === "vertical"}
                  onClick={() => handleChangeFormat("vertical")}
                >
                  <VideoFormatTitle selected={selectedFormat === "vertical"}>
                    Vertical
                  </VideoFormatTitle>
                  <VideoFormatImg
                    selected={selectedFormat === "vertical"}
                    alt="Vertical"
                    src={`${process.env.PUBLIC_URL}/img/video/vertical.svg`}
                  />
                  <VideoFormatDescription
                    selected={selectedFormat === "vertical"}
                  >
                    Idéal pour Instagram, TikTok
                  </VideoFormatDescription>
                </VideoFormatContainer>
              </HorizontalContainerCenter>

              {/* {selectedSlide !== undefined ? <selectedSlide.Inspector projectConfig={}  /> : null} */}
            </Card>
          ) : null}

          {selectedFormat !== undefined ? (
            <>
              <Card title="Etape 3: Durée de la vidéo">
                <InputWithLabel
                  label="Début de la vidéo"
                  value={videoStart}
                  onChange={handleChangeVideoStart}
                  error={isVideoStartError}
                />
                <InputWithLabel
                  label="Fin de la vidéo"
                  value={videoEnd}
                  onChange={handleChangeVideoEnd}
                  error={isVideoEndError}
                />

                <VideoDuration>
                  Durée de la vidéo: {secondsToString(videoDuration)}
                </VideoDuration>
                <SubTitle>Compatible avec</SubTitle>
                <SocialAvailable
                  duration={videoDuration}
                  maxDuration={[60, 600]}
                  icon={faInstagram}
                  socialName="Instagram"
                  socialDescription="Maximum 60s pour les stories et reels, 10min pour les videos dans le feed"
                />
                <SocialAvailable
                  duration={videoDuration}
                  maxDuration={139}
                  icon={faTwitter}
                  socialName="Twitter"
                  socialDescription="Maximum 2min20"
                />
                <SocialAvailable
                  duration={videoDuration}
                  maxDuration={600}
                  icon={faTiktok}
                  socialName="TikTok"
                  socialDescription="Maximum 10min"
                />
                <SocialAvailable
                  duration={videoDuration}
                  maxDuration={Infinity}
                  icon={faYoutube}
                  socialName="YouTube"
                  socialDescription="Pas de maximum"
                />
              </Card>

              <Card title="Etape 4: Publication">
                <InputWithLabel
                  label="Adresse email"
                  value={emailAddress}
                  onChange={(e: any) => setEmailAddress(e.target.value)}
                  type="email"
                  error={
                    !isEmail(emailAddress)
                      ? "Adresse email invalide"
                      : undefined
                  }
                />
              </Card>

              <Button
                size="lg"
                disabled={
                  !isEmail(emailAddress) ||
                  isVideoStartError !== undefined ||
                  isVideoEndError !== undefined
                }
                onClick={startConvert}
              >
                Convertir
              </Button>
            </>
          ) : null}
        </>
      </Container>
    </>
  );
}
