import { Suspense, useCallback } from "react";
import tw from "twin.macro";

import Button from "../atoms/Button";
import { useCurrentClip } from "../stores/currentClip";
import { useProjectConfig } from "../stores/project";
import SlideRegistry from "../video/SlideRegistry";

const StyledContainer = tw.div`
w-8/12 
max-w-md
h-full 

overflow-auto

flex 
flex-col 

items-start justify-items-start 
gap-3

box-border p-2 

bg-blue-900
`;

export const SlideInspector = () => {
  const [selected, setSelected] = useCurrentClip();
  const [projectConfig, setProjectConfig] = useProjectConfig();
  const slide = projectConfig.slides.find((a) => a.id === selected);

  const Inspector = slide ? SlideRegistry.get(slide.type)?.Inspector : null;

  const deleteSlide = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this slide?")) {
      setProjectConfig((projectConfig) => ({
        ...projectConfig,
        slides: [...projectConfig.slides.filter((a) => a.id !== slide?.id)],
      }));
      setSelected(null);
    }
  }, [setProjectConfig, slide, setSelected]);

  const setConfig = useCallback(
    (newConfig) =>
      slide
        ? setProjectConfig((projectConfig) => ({
            ...projectConfig,
            slides: [
              ...projectConfig.slides.slice(
                0,
                projectConfig.slides.indexOf(slide)
              ),
              { ...slide, config: newConfig },
              ...projectConfig.slides.slice(
                projectConfig.slides.indexOf(slide) + 1
              ),
            ],
          }))
        : void 0,
    [slide, setProjectConfig]
  );

  return (
    <StyledContainer>
      <h3>Inspector</h3>
      {slide ? (
        <>
          <div>
            {slide.type} {slide.id}
          </div>
          {Inspector ? (
            <div style={{ maxWidth: "100%" }}>
              <Suspense fallback={<p>Loading...</p>}>
                <Inspector
                  projectConfig={projectConfig}
                  cue={slide}
                  config={slide.config}
                  setConfig={setConfig}
                />
              </Suspense>
            </div>
          ) : null}
          <pre style={{ maxWidth: "100%" }}>{JSON.stringify(slide.config)}</pre>
          <Button variant="danger" onClick={deleteSlide}>
            Supprimer la slide
          </Button>
        </>
      ) : (
        "No slide selected"
      )}
    </StyledContainer>
  );
};

export default SlideInspector;
