import ulog from 'ulog';
import formatter from 'ulog/mods/formats/formatter';

ulog.use({
  formats: {
    fullname: function (ctx: any, rec: any) {
      return formatter(ctx, rec, { color: "logger" }, function () {
        return rec.logger.name;
      });
    },
  },
  settings: {
    format: {
      prop: {
        default: "fullname perf cr lvl",
      },
    },
  },
});

const logger = (name: string, config?: object) =>
  ulog(`youpod-next:frontend:${name}`, config);

export default logger;
