import tw from "twin.macro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export const ProfilePictureComponent = tw.div`
  w-[35px]
  h-[35px]
  border-solid
  border-b-gray-300
  border-2
  rounded-full

  flex
  justify-center
  items-center

  hover:cursor-pointer
`;

export const ProfiulePicture = (): React.ReactElement => {
  return (
    <ProfilePictureComponent>
      <FontAwesomeIcon icon={faUser} />
    </ProfilePictureComponent>
  );
};

export default ProfiulePicture;
