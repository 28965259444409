import { checkStatus, host } from ".";

export interface CreateVideoBody {
  email: string;
  format: string;
  startTime: string;
  endTime: string;
  cover: string;
  audio: string;
  epTitle: string;
  podTitle: string;
  podSubTitle: string;
  rss?: string;
  podcast_id?: number;
}

const video = {
  new: (body: CreateVideoBody): Promise<any> => {
    return fetch(`${host}/video`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default video;
