import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

export const isDraggingAnythingStore = atom<boolean>(false);

export const useSetIsDraggingAnything = () =>
  useSetAtom(isDraggingAnythingStore);

export const useIsDraggingAnything = () =>
  useAtomValue(isDraggingAnythingStore);

export const useIsDraggingAnythingState = () =>
  useAtom(isDraggingAnythingStore);
