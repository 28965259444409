import tw from 'twin.macro';

export const StyledEditor = tw.div`
p-0 m-0 
absolute 
h-full w-full 

bg-gray-800 text-gray-100 
font-sans

flex flex-col

`;

export const StyledNav = tw.div`
p-1 m-0 
h-8 w-full 

box-border

flex items-center justify-between 
bg-gray-700 border-b-blue-900

drop-shadow
`;

export const StyledTitle = tw.div`
text-sm font-bold
`;

export const StyledMain = tw.main`
w-full h-auto flex-grow flex flex-wrap overflow-hidden
`;

export const StyledSidebar = tw.main`
w-1/2 h-2/3 flex flex-row
`;
