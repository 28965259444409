import api from "../../api";
import InputWithLabel from "../../molecules/InputWithLabel";

import SubmitButton from "../../atoms/SubmitButton";
import { useAtom } from "jotai";
import { hasCheckLoginAtom, userAtom } from "../../stores/user";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Card from "../../atoms/Card";
import Container from "../../atoms/Container";
import Button from "../../atoms/Button";

export default function Login() {
  const navigate = useNavigate();
  const [, setUser] = useAtom(userAtom);
  const [, setHasCheckLogin] = useAtom(hasCheckLoginAtom);

  function submitLogin(values: any, { setSubmitting }: any) {
    api.auth
      .login(values)
      .then((body) => {
        localStorage.setItem("jwt", body.jwt);
        setUser(body.user);
        setHasCheckLogin(true);
        setSubmitting(false);
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  }

  return (
    <Container verticalCenter>
      <Card title="Se connecter">
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={submitLogin}
          validate={(values) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Requis";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Addresse email invalide";
            }

            if (!values.password) {
              errors.password = "Requis";
            }

            return errors;
          }}
        >
          {({
            isValid,
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <InputWithLabel
                label="Email"
                placeholder="hello@example.com"
                autoFocus
                value={values.email}
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                required
              />
              <InputWithLabel
                label="Mot de passe"
                type="password"
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                required
              />
              <SubmitButton
                type="submit"
                value="Se connecter"
                disabled={!isValid}
              />
              <Button onClick={() => navigate("/register")} variant="secondary">
                S'inscrire
              </Button>
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
}
