import { Project } from "./@types/Project";
import SCREENS, { Screen, SCREEN_FORMATS } from "./SCREENS";

export { default as ProjectConfigFixture } from "./ProjectConfig.fixture";

export type ProjectConfig = Project & {
  screen: Screen;
  duration: number;
};

export const NewProjectConfig: (
  config: Partial<ProjectConfig>
) => ProjectConfig = (config = {}) => ({
  screen: SCREENS[SCREEN_FORMATS[0]],
  duration: 120,
  audio: "",
  webvtt: null,
  title: "",
  slides: [],
  ...config,
});
