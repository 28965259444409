import ulog from "ulog";
import formatter from "ulog/mods/formats/formatter";

if (process.env.NODE_ENV === "development") {
  if (localStorage.getItem("log") === null) {
    localStorage.setItem("log", "all");
  }
}

ulog.use({
  formats: {
    fullname: function (ctx: any, rec: any) {
      return formatter(ctx, rec, { color: "logger" }, function () {
        return rec.logger.name;
      });
    },
  },
  settings: {
    format: {
      prop: {
        default: "fullname perf cr lvl",
      },
    },
  },
});

const logger = (name: string, config?: object) =>
  ulog(`youpod-next:slides:${name}`, config);

export default logger;
