import { Slide, SlideConfig } from "./@types/Slide";

const _registry: Record<string, Slide<SlideConfig>> = {};

export const register = (component: Slide<SlideConfig>) => {
  _registry[component.key] = component;
};

export const get = (name: string) => _registry[name];

export const getAll = () => _registry;

export const SlideRegistry = {
  register,
  get,
  getAll,
};

export default SlideRegistry;
