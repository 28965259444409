import tw from "twin.macro";
import ProfilePicture from "../../atoms/ProfilePicture";
import YouPodLogo from "../../atoms/YouPodLogo";

export const TopBarContainer = tw.div`
  sticky
  top-0
  w-full
  h-[50px]

  bg-white

  flex
  justify-center

  shadow

  p-3
`;

const MiddleContainer = tw.div`
  max-w-6xl
  w-full

  flex
  justify-between
  items-center
`;

export const TopBar = (): React.ReactElement => (
  <TopBarContainer>
    <MiddleContainer>
      <YouPodLogo />
      <ProfilePicture />
    </MiddleContainer>
  </TopBarContainer>
);

export default TopBar;
