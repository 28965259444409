import api from "../../api";
import InputWithLabel from "../../molecules/InputWithLabel";

import SubmitButton from "../../atoms/SubmitButton";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Card from "../../atoms/Card";
import Container from "../../atoms/Container";
import Button from "../../atoms/Button";

export default function Register() {
  const navigate = useNavigate();

  function submitRegister(values: any, { setSubmitting }: any) {
    api.auth
      .register(values)
      .then((body) => {
        navigate("/login");
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  }

  return (
    <Container verticalCenter>
      <Card title="S'inscrire">
        <Formik
          initialValues={{ email: "", password: "", passwordRepeat: "" }}
          onSubmit={submitRegister}
          validate={(values) => {
            const errors: any = {};
            if (!values.email) {
              errors.email = "Requis";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Adresse email invalide";
            }

            if (!values.password) {
              errors.password = "Requis";
            }

            if (!values.passwordRepeat) {
              errors.passwordRepeat = "Requis";
            } else if (values.password !== values.passwordRepeat) {
              errors.passwordRepeat =
                "Les mots de passes ne sont pas les mêmes";
            }

            return errors;
          }}
        >
          {({
            isValid,
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <InputWithLabel
                label="Email"
                placeholder="hello@example.com"
                autoFocus
                value={values.email}
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email}
                required
              />
              <InputWithLabel
                label="Mot de passe"
                type="password"
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                required
              />
              <InputWithLabel
                label="Répétez le mot de passe"
                type="password"
                value={values.passwordRepeat}
                name="passwordRepeat"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.passwordRepeat}
                required
              />
              <SubmitButton
                type="submit"
                value="S'inscrire"
                disabled={!isValid}
              />
              <Button onClick={() => navigate("/login")} variant="secondary">
                Se connecter
              </Button>
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
}
