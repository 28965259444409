import React, { MouseEventHandler, RefObject } from "react";
import tw, { theme } from "twin.macro";

import { NoSelect } from "../../atoms/NoSelect";
import logger from "../../logger";
import { useZoomLevel } from "../../stores/timeline";
import { SlideData } from "../../video/@types/SlideData";

const StyledClip = tw.div`
flex flex-col 

bg-green-600

rounded
border

shadow-xl

absolute
h-full
max-h-24
z-50

cursor[grab]
active:cursor[grabbing]
`;

const Title = tw.h1`
    m-0
    text-sm
    select-none
`;

const StyledContainer = tw.div`
  h-full
  w-full

  box-border 
  p-2 

  shadow-inner
`;

export type SlideClipProps = {
  data: SlideData;
};

export const SlideClipView = React.forwardRef(
  (
    {
      hidden,
      selected,
      onMouseDown,
      data,
    }: SlideClipProps & {
      hidden?: boolean;
      selected?: boolean;
      onMouseDown?: MouseEventHandler<HTMLDivElement>;
    },
    ref
  ) => {
    const { from, to } = data;
    const duration = to - from;
    const title = `${data.type} ${data.id}`;

    logger("SlideClipView")("props:", {
      from,
      duration,
      title,
      hidden,
    });

    const { framesToPixels } = useZoomLevel();

    return (
      <StyledClip
        style={{
          left: framesToPixels(from),
          width: framesToPixels(duration),
          opacity: hidden ? 0 : 1,
          cursor: "grab",
          borderWidth: selected ? 2 : 1,
          padding: selected ? 0 : 1,
          borderColor: selected
            ? theme("colors.yellow.200")
            : theme("colors.gray.200"),
        }}
        onMouseDown={onMouseDown}
        ref={ref as RefObject<HTMLDivElement>}
      >
        <StyledContainer>
          <Title>{title}</Title>
          <NoSelect>{JSON.stringify({ from, duration })}</NoSelect>
        </StyledContainer>
      </StyledClip>
    );
  }
);

export default SlideClipView;
