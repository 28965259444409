import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { hasCheckLoginAtom, userAtom } from "./stores/user";

import AppProvider from "./AppProvider";
// ** Auth
import Login from "./pages/Auth/Login";
import NeedLogin from "./atoms/NeedLogin";
import { ReactQueryDevtools } from "react-query/devtools";
import Register from "./pages/Auth/Register";
// Pages
import SlideEditor from "./pages/SlideEditor";
// ** VideoGeneration
import VideoGeneration from "./pages/VideoGeneration/MainPage";
import api from "./api";
import logger from "./utils/logger";
import { useAtom } from "jotai";
import { useEffect } from "react";

const log = logger("page:App");

function App() {
  const [, setHasCheckLogin] = useAtom(hasCheckLoginAtom);
  const [, setUser] = useAtom(userAtom);

  useEffect(() => {
    checkWhoami();

    function checkWhoami() {
      log("Keep connecion");
      api.auth
        .whoami()
        .then((body) => {
          log("Connection validated");
          localStorage.setItem("jwt", body.jwt);
          setUser(body.user);
          setHasCheckLogin(true);
          setTimeout(checkWhoami, 30 * 60 * 60 * 1000);
        })
        .catch((err) => {
          log("Connection reject");
          localStorage.removeItem("jwt");
          setHasCheckLogin(true);
        });
    }
  }, [setHasCheckLogin, setUser]);

  return (
    <AppProvider>
      <BrowserRouter basename="app">
        <Routes>
          {/* Auth */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />

          {/* Classic Videos */}
          <Route
            path="video"
            element={
              <NeedLogin>
                <VideoGeneration />
              </NeedLogin>
            }
          />

          <Route path="editor" element={<SlideEditor />} />
          <Route path="*" element={<Navigate to="/editor" />} />
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools />
    </AppProvider>
  );
}

export default App;
