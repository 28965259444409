import { ProjectConfig } from "./ProjectConfig";

export default {
  screen: { width: 1080, height: 1080, fps: 30 },
  duration: 120,
  audio: "https://next.youpod.io/app/demo/audio.mp3",
  title: "It Takes Two - Kapsule Pixel",
  id: "1644241186054",
  slides: [
    { id: 1, type: "podcasts.tips", from: 0, to: 90, config: {} },
    {
      id: 2,
      type: "audioviz",
      from: 90,
      to: 300,
      config: { color: "#5fff4e" },
    },
    {
      id: 3,
      type: "logo_text_slide",
      from: 300,
      to: 600,
      config: {
        text: "KapsulePixel",
        src: "https://next.youpod.io/app/demo/cover.jpg",
      },
    },
    {
      id: 5,
      type: "placeholder",
      from: 600,
      to: 900,
      config: { text: "coucou" },
    },
  ],
  webvtt: `
WEBVTT

1
00:00:00.000 --> 00:00:00.558
Pour moi,

2
00:00:00.558 --> 00:00:01.934
les meilleurs moments dans les jeux vidéos,

3
00:00:01.934 --> 00:00:03.282
c’est quand on les partage avec des personnes.

4
00:00:03.282 --> 00:00:05.014
C’est d’un de ces formidables moments de partage

5
00:00:05.014 --> 00:00:06.279
que je vais vous parler aujourd’hui,

6
00:00:06.279 --> 00:00:09.204
que j’ai eu le plaisir de l’avoir avec mon père sur le jeu It Takes Two,

7
00:00:09.204 --> 00:00:10.865
sorti le 26 mars 2021,

8
00:00:10.865 --> 00:00:13.406
développé par Hazelight et édité par Electronic Arts.

9
00:00:13.406 --> 00:00:15.543
Le début du jeu prend place dans une famille parfaitement normale.

10
00:00:15.543 --> 00:00:16.905
May et Cody les deux parents,

11
00:00:16.905 --> 00:00:18.380
mariés et parents de la petite Rose,

12
00:00:18.380 --> 00:00:19.431
ne peuvent plus s’encadrer

13
00:00:19.431 --> 00:00:20.795
et sont à deux doigts de divorcer.

14
00:00:20.795 --> 00:00:21.897
Leur fille, malheureuse,

15
00:00:21.897 --> 00:00:25.455
décide de faire appel au formidable livre du Docteur Hakim, sur l’amour.

16
00:00:25.455 --> 00:00:26.856
Elle va commencer à pleurer dessus

17
00:00:26.856 --> 00:00:28.137
et l’histoire va enfin pouvoir commencer.

18
00:00:28.137 --> 00:00:30.823
May et Cody vont en effet se retrouver propulser dans le corps

19
00:00:30.823 --> 00:00:32.463
d’une petite poupée de glaise pour Cody,

20
00:00:32.463 --> 00:00:33.906
et d’une figurine en bois pour May.

21
00:00:33.906 --> 00:00:35.089
Après le moment de panique,

22
00:00:35.089 --> 00:00:37.375
et une explication alambiquée du Livre de l’Amour,

23
00:00:37.375 --> 00:00:39.026
vous comprenez que vous allez devoir collaborer.

24
00:00:39.026 --> 00:00:41.390
It Takes Two m’a vraiment bluffé par ses mécaniques.

25
00:00:41.390 --> 00:00:43.428
Prenant à la base la forme d’un simple jeu de plateforme,

26
00:00:43.428 --> 00:00:44.986
il est en fait blindé de bonnes idées

27
00:00:44.986 --> 00:00:46.893
et chaque niveau ajoutera ses nouvelles mécaniques.

28
00:00:46.893 --> 00:00:47.582
Par exemple,

29
00:00:47.582 --> 00:00:49.648
vous allez pouvoir avoir un marteau pour casser le décors,

30
00:00:49.648 --> 00:00:51.372
et des clous à lancer pour créer des plateformes,

31
00:00:51.372 --> 00:00:54.017
ou une mécanique à base de produit inflammable à envoyer,

32
00:00:54.017 --> 00:00:56.018
et ensuite à allumer par l’autre personne.

33
00:00:56.018 --> 00:00:58.485
La communication restera quand même l’aspect principal du jeu,

34
00:00:58.485 --> 00:00:59.873
vous devrez toujours réfléchir à deux,

35
00:00:59.873 --> 00:01:02.650
et chacun pourra résoudre les solutions d’une manière différente.

36
00:01:02.650 --> 00:01:03.610
Et dernier petit point,

37
00:01:03.610 --> 00:01:05.280
régulièrement vous pourrez trouver des mini jeux

38
00:01:05.280 --> 00:01:07.735
qui ne vous demanderont pas de coopérer avec votre allié,

39
00:01:07.735 --> 00:01:09.257
mais de le combattre comme un ennemi.

40
00:01:09.257 --> 00:01:11.615
Par exemple autour d’un bon vieux tir à la corde,

41
00:01:11.615 --> 00:01:13.420
ou d’un duel de rodéo sur cheval mécanique.

42
00:01:13.420 --> 00:01:14.065
Les niveaux,

43
00:01:14.065 --> 00:01:16.216
au-delà des mécaniques dont j’ai déjà parlé, seront inventifs,

44
00:01:16.216 --> 00:01:18.061
avec l’entrée au services d’écureuils militaires,

45
00:01:18.061 --> 00:01:20.772
ou l’exploration d’une chambre d’enfants avec pleins d’énigmes autour des jouets.

46
00:01:20.772 --> 00:01:22.413
Je ne vais bien sur pas tous vous les raconter

47
00:01:22.413 --> 00:01:24.198
pour éviter de gâcher le sentiment de découverte

48
00:01:24.198 --> 00:01:25.806
que je trouve tellement important pour ce jeu.

49
00:01:25.806 --> 00:01:27.032
Au niveau de l’histoire et des dialogue,

50
00:01:27.032 --> 00:01:28.759
le tout sera aussi excellent,

51
00:01:28.759 --> 00:01:31.049
avec des doubleurs parfaitement bien choisis pour chacun des personnages,

52
00:01:31.049 --> 00:01:33.775
des piques que s’échangeront nos principaux protagonistes

53
00:01:33.775 --> 00:01:35.526
tel le vieux couple dissonant qu’ils sont,

54
00:01:35.526 --> 00:01:37.526
mais surtout pour tous les ennemies auxquels ils feront face

55
00:01:37.526 --> 00:01:40.130
qui seront tous haut en couleur et très surprenants.

56
00:01:40.130 --> 00:01:40.868
Juste avant de conclure,

57
00:01:40.868 --> 00:01:42.644
la direction artistique du jeu est vraiment correcte.

58
00:01:42.644 --> 00:01:44.023
Bien que les visages soient un peu spéciaux,

59
00:01:44.023 --> 00:01:46.224
les poupées et tout le reste du jeu seront parfaitement propres

60
00:01:46.224 --> 00:01:47.777
et dans des couleurs très chaudes.

61
00:01:47.777 --> 00:01:50.825
Et la musique elle reste vraiment très agréable à entendre sur tous les points.

62
00:01:50.825 --> 00:01:51.477
Pour terminer,

63
00:01:51.477 --> 00:01:54.439
je dirais donc que Hazelight a démontré son expertise sur les jeux de coopération

64
00:01:54.439 --> 00:01:56.030
et a bien mérité son titre de Jeu de l’année.

65
00:01:56.030 --> 00:01:59.134
Pour paraphraser une vidéo géniale de DrGeraud sur le Design Bordélique

66
00:01:59.134 --> 00:02:00.100
(ou Messy Design)

67
00:02:00.100 --> 00:02:01.420
que vous trouverez dans la description,

68
00:02:01.420 --> 00:02:03.154
It Takes Two développe tellement d’idées différentes

69
00:02:03.154 --> 00:02:05.251
que mêmes si elles ne sont pas toutes parfaitement bien exécutée,

70
00:02:05.251 --> 00:02:07.631
elles ne durent pas suffisamment longtemps pour mal se passer.

71
00:02:07.631 --> 00:02:09.891
Si vous avez une autre personne avec qui jouer sur votre canapé,

72
00:02:09.891 --> 00:02:10.856
c’est clairement un must have,

73
00:02:10.856 --> 00:02:14.017
et sinon, il suffit de n’acheter qu’une seule copie pour jouer en ligne avec vos amis!

74
00:02:14.017 --> 00:02:17.605
Il est disponible pour 40€ un peut partout, ou dans le Xbox Game Pass!


`,
} as ProjectConfig;
